import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./login.css";
import httpService from '../../../Common/httpServices/http.service';

async function loginUser(credentials) {
    const response = await httpService.POST_NO_TOKEN("/public/login", credentials);
    // console.log(response);
    if (response.data.status === "Success") {
        return { token: response.data };
    } else {
        return null;
    }
}

const Login = (props) => {
    // console.log(props)
    const [userId, setUserName] = useState();
    const [password, setPassword] = useState();

    // const
    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        const loginInfo = await loginUser({
            userId,
            password
        });

        try {
            if (loginInfo.token.token !== null) {
                let userInfo = loginInfo.token.user;
                sessionStorage.setItem("token", loginInfo.token.token);
                sessionStorage.setItem("userId", userInfo.userId);
                sessionStorage.setItem("userName", userInfo.userName);
                window.location.reload(true);
            }
        } catch (error) {
            alert("Invalid Credentials !!!");
        }

    }

    return (
        <Fragment>
            <div className='loginform_1'>
                <div className='login_form'>
                    <h2>Sign In</h2>
                    <form>
                        <input onChange={e => setUserName(e.target.value)} type="email" name="login_email" placeholder='Type your email here' />
                        <input onChange={e => setPassword(e.target.value)} type="password" name="login_password" placeholder='Type your password here' />
                        <button onClick={handleSubmit}>
                            Login
                        </button>
                    </form>
                    {/* <div className='forget_form'>
                        <span>Forgot your password?</span>
                        <span className='login_reset'>Reset It</span>
                    </div> */}
                </div>
            </div>
        </Fragment>
    )
}

export default Login;