import React, { Fragment, useEffect, useState } from "react";
import './videoTagGeneration.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useLocation } from "react-router-dom";

import HttpService from '../../../Common/httpServices/http.service';
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import Select from 'react-select';

var video_id;

const VideoTagGenerationForm = () => {
    const [formValues, setFormValues] = useState({ status: '', slotWidth: "640", slotHeight: "360" });
    const [videoConfig, setVideoConfig] = useState({});

    const macroOptionsDV360 = [
        { 'value': '${CACHEBUSTER}', 'label': 'cbuster' },
        { 'value': '${CLICK_URL_ENC}', 'label': 'gclckunesc' }
    ];

    const macroOptionsGAM360 = [
        { 'value': '%%CACHEBUSTER%%', 'label': 'cbuster' },
        { 'value': '%%CLICK_URL_ESC%%', 'label': 'gclckunesc' }
    ];

    let navigate = useNavigate();
    let location = useLocation();
    let videoId;
    if (location.state.id) {
        videoId = location.state.id
    };
    try {
        if (location.state.id) {
            video_id = location.state.id;
        } else {
            navigate("/");
        }
    } catch (err) {
        navigate("/");
    }

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'slotSize' && value !== 'custom') {
            let slotDimension = value.split('*');
            let slotWidth = slotDimension[0];
            let slotHeight = slotDimension[1];
            setFormValues({
                ...formValues,
                'slotSize': value,
                'slotWidth': slotWidth,
                'slotHeight': slotHeight,
            });
            return true;
        }
        if (name === 'slotSize' && value === 'custom') {
            setFormValues({
                ...formValues,
                'slotSize': value,
                'slotWidth': '',
                'slotHeight': '',
            });
            return true;
        }
        if (name === 'network') {
            setFormValues({
                ...formValues,
                [name]: value,
                'macros': ''
            });
            return true;
        }
        // if(name === 'draft') {
        //     setFormValues({
        //         ...formValues,
        //         [name]: checked,
        //         'macros': ''
        //     });
        //     return true;
        // }
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleMultiselectInputChange = function (e) {
        let data = ''
        e.map((value, index) => {
            if (!data) { data = value.label } else { data += ',' + value.label }
        });
        setFormValues({
            ...formValues,
            'macros': data
        });

    }

    const redirectTo = (redireactUrlOrRoute) => {
        window.location.href = `${redireactUrlOrRoute}/${video_id}`;
    }

    async function getVideoConfigByID(id) {
        try {
            const response = await HttpService.GET("/private/main-video/" + video_id);
            const data = response.data.object.mainVideo;

            console.log(data);

            if (response.data.status === 'SUCCESS') {
                setVideoConfig(data);
            }
            return response;
        } catch (err) {
            isSessionExpire();
        }
    }

    function generateTag() {
        let generatedTag = '<iframe id="adcontainer-';
        let videoId = videoConfig.id;
        let videoGuid = videoConfig.truereachSiteGuid;

        if (!videoGuid && formValues.status !== 'DRAFT') {
            alert("Generate Draft tag or map Truereach site id on Video Configuration page");
            return false;
        }

        if (formValues.status === '') {
            alert("Please select Tag Status before generating tag.");
            return false;
        }

        videoGuid = videoGuid || videoConfig.videoGuid;
        generatedTag += videoGuid + '"';
        generatedTag += ' src="//video.momagic.com/interactive-video/';
        generatedTag += videoGuid;
        generatedTag += '?v=';
        generatedTag += videoId;
        generatedTag += '&siteId=' + videoGuid;
        if (formValues.slotWidth) {
            generatedTag += '&w=' + formValues.slotWidth;
        }
        if (formValues.slotHeight) {
            generatedTag += '&h=' + formValues.slotHeight;
        }
        if (formValues.network === "DV360") {
            formValues.macros.split(',').map((value, index) => {
                for (let i = 0; i < macroOptionsDV360.length; i++) {
                    if (value === macroOptionsDV360[i].label) {
                        generatedTag += '&' + value + '=' + macroOptionsDV360[i].value;
                    }
                }
            });
        } else if (formValues.network === "GAM360") {
            formValues.macros.split(',').map((value, index) => {
                for (let i = 0; i < macroOptionsGAM360.length; i++) {
                    if (value === macroOptionsGAM360[i].label) {
                        generatedTag += '&' + value + '=' + macroOptionsGAM360[i].value;
                    }
                }
            });
        }
        if (formValues.status !== 'PROD') {
            generatedTag += '&tag-status=' + formValues.status;
        }
        generatedTag += '"';
        if (formValues.slotWidth) {
            generatedTag += ' width="' + formValues.slotWidth + '"';
        }
        if (formValues.slotHeight) {
            generatedTag += ' height="' + formValues.slotHeight + '"';
        }

        generatedTag += ' frameborder="0" marginheight="0" marginwidth="0" style="border:none;" scrolling="no" sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-forms allow-modals"></iframe>'
        setFormValues({
            ...formValues,
            "generatedTag": generatedTag,
        });
        return true;
    }

    const manageSelectValues = (value, optionsArr) => {
        if (!value) {
            return '';
        }
        for (let i = 0; i < optionsArr.length; i++) {
            if (value === optionsArr[i].value) {
                return optionsArr[i];
            }
        }
    }

    useEffect(() => {
        const id = video_id;
        getVideoConfigByID(id);
    }, []);

    return (
        <Fragment>
            <Container>
                <div className="vd_config">
                    <Row style={{ flexFlow: "row", justifyContent: "space-between" }}>
                        <Col md={12} style={{ width: "auto" }}> <h2>Video Tag Generation</h2> </Col>
                        <Col md={12} style={{ width: "auto" }}> <h2 >Video id : {videoId}</h2> </Col>
                    </Row>
                    {/* <Col md={12}> <h2>Video Tag Generation</h2></Col> */}
                    <div className="hotspot_position_1">
                        <div className="video_config">
                            <Row>
                                <Col md={3}><h4>Ad Slot Size</h4></Col>
                                <Col md={9}>
                                    <select name='slotSize' value={formValues.slotSize} onChange={handleInputChange}>
                                        {/* <option value="640*360">--Select an option--</option> */}
                                        <option value="640*360">640*360</option>
                                        <option value="1280*720">1280*720</option>
                                        <option value="1440*1080">1440*1080</option>
                                        <option value="1920*1080">1920*1080</option>
                                        <option value="360*640">360*640</option>
                                        <option value="720*1280">720*1280</option>
                                        <option value="1080*1440">1080*1440</option>
                                        <option value="1080*1920">1080*1920</option>
                                        <option value="300*250">300*250</option>
                                        <option value="336*280">336*280</option>
                                        <option value="1200*627">1200*627</option>
                                        <option value="1200*628">1200*628</option>
                                        <option value="627*1200">627*1200</option>
                                        <option value="628*1200">628*1200</option>
                                        <option value="600*600">600*600</option>
                                        <option value="720*720">720*720</option>
                                        <option value="1080*1080">1080*1080</option>
                                        <option value="1920*1920">1920*1920</option>
                                        <option value="360*450">360*450</option>
                                        <option value="320*480">320*480</option>
                                        <option value="480*320">480*320</option>
                                        <option value="1024*768">1024*768</option>
                                        <option value="768*1024">768*1024</option>
                                        <option value="custom">Custom Size</option>
                                    </select>
                                </Col>
                            </Row>
                            {
                                (String(formValues.slotSize) === "custom") ?
                                    <Row>
                                        <Col md={3}><h4>Slot Height(in px)</h4></Col>
                                        <Col md={9}><input name="slotHeight" value={formValues.slotHeight} onChange={handleInputChange} type="text" id="poster_img" className="config_poster_inp" placeholder="Enter number here" />
                                        </Col>
                                    </Row>
                                    : null
                            }
                            {
                                (String(formValues.slotSize) === "custom") ?
                                    <Row>
                                        <Col md={3}><h4>Slot Width(in px)</h4></Col>
                                        <Col md={9}><input name="slotWidth" value={formValues.slotWidth} onChange={handleInputChange} type="text" id="poster_img" className="config_poster_inp" placeholder="Enter number here" />
                                        </Col>
                                    </Row>
                                    : null
                            }
                        </div>
                        <Row>
                            <Col md={3}><h4>Network</h4></Col>
                            <Col md={9}>
                                <select name='network' className="keepplaying_1" value={formValues.network} onChange={handleInputChange}>
                                    <option value="">--Select an option--</option>
                                    <option value="DV360">DV360</option>
                                    <option value="GAM360">GAM360</option>
                                </select>
                            </Col>
                        </Row>
                        {
                            (formValues.network === 'DV360') ?
                                <Row>
                                    <Col md={3}><h4>DV360 Macros</h4></Col>
                                    <Col md={9}>
                                        <Select
                                            name="macros"
                                            value={manageSelectValues(formValues.macros, macroOptionsDV360)}
                                            onChange={handleMultiselectInputChange}
                                            options={macroOptionsDV360}
                                            classNamePrefix="select"
                                            className="basic-multi-select"
                                            isMulti
                                        />
                                    </Col>
                                </Row>

                                : null
                        }
                        {
                            (formValues.network === 'GAM360') ?
                                <Row>
                                    <Col md={3}><h4>GAM360 Macros</h4></Col>
                                    <Col md={9}>
                                        <Select
                                            name="macros"
                                            value={manageSelectValues(formValues.macros, macroOptionsGAM360)}
                                            onChange={handleMultiselectInputChange}
                                            options={macroOptionsGAM360}
                                            classNamePrefix="select"
                                            className="basic-multi-select"
                                            isMulti
                                        />
                                    </Col>
                                </Row>
                                : null
                        }
                        <Row style={{ alignItems: "center" }}>
                            <Col md={3}><h4>Tag Status</h4></Col>
                            <Col md={9}>
                                <select name='status' value={formValues.status} onChange={handleInputChange}>
                                    <option value="">--Select an option--</option>
                                    <option value="DRAFT">Draft - Only For Testing</option>
                                    <option value="TEST">Test - Staging Environment</option>
                                    <option value="PROD">Production Environment</option>
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <button className="btn_save" onClick={generateTag}>Generate</button>
                                <button className="btn_cancel" onClick={() => redirectTo("/player")}>Cancel</button>
                            </Col>
                        </Row>
                        {
                            formValues.generatedTag ?
                                <Row>
                                    <Col md={3}><h4>Generated Tag</h4></Col>
                                    <Col md={9}><textarea disabled name="generatedTag" value={formValues.generatedTag} onChange={handleInputChange} type="text" id="poster_img" className="config_poster_inp" placeholder="Generated Tag" />
                                    </Col>
                                </Row>
                                : null
                        }
                    </div>
                </div>
            </Container>
        </Fragment>
    )
}
export default VideoTagGenerationForm;