import React, { Fragment } from "react";
import './footer.css'


const Footer = () => {
    return (
        <Fragment>
            <div className="dash_footer">
                <div className="dash_copyright">
                    <p>Copyright 2023 © All Right Reserved</p>
                </div>
                <div className="dash_foot_menu">
                    <ul>
                        <li><a href="#">Disclaimer</a></li>
                        <li><a href="#">Privacy</a></li>
                        <li><a href="#">Terms of service</a></li>
                        <li><a href="#">Copyright</a></li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
}

export default Footer;