import React from "react";

const PreviewPlayer = (props) => {
    // debuggersss
    // console.log(props);
    return (
        <React.Fragment>
            <video id={props.id} preload={props.preload} style={{ width: props.width, height: props.height }}
                poster="" autoPlay={props.autoPlay} controls data-setup='{ }'>
                <source id="source" src={props.videoPath} type="video/mp4" />
            </video>
            {
                props.renderContent ?
                    <div style={{ position: "absolute", top: "16px", width: "100%" }} onClick={props.editMacro}
                        dangerouslySetInnerHTML={{ __html: props.renderContent }}></div>
                    : null
            }
        </React.Fragment>
    );
}
export default PreviewPlayer;