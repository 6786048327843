import React, { Fragment } from "react";

const Error404 = () => {
    return (
        <Fragment>
            <center>
                <h1>404 Page Not Found</h1>
            </center>
        </Fragment>
    );
}

export default Error404;