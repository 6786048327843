import React, { Fragment, useEffect, useState } from "react";
import './hotspotForm.css';

import videojs from 'video.js';
import fileUtils from "../../../utils/file-utils/fileUtils";
import Helper from "../../../Common/helper/helper";
import HttpService from "../../../Common/httpServices/http.service";
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import TextEditor from "../../../Common/editor/editor";
import validator from "validator";
import Select from 'react-select';
import { getFormQuizListAndOptions, getImageListAndOptions, getMainVideoListAndOptions } from "./../common-methods/commonMethods";

const HotspotForm = (props) => {

    const [player, setPlayer] = useState();
    const [showTimeMm, setShowTimeMm] = useState("");
    const [showTimeSs, setShowTimeSs] = useState("");
    const [hideTimeMm, setHideTimeMm] = useState("");
    const [hideTimeSs, setHideTimeSs] = useState("");
    const [pauseTimeEnable, setPauseTimeEnable] = useState(false);
    const [pauseTimeSs, setPauseTimeSs] = useState(0);
    const [file, setFile] = useState("");
    const [label, setLabel] = useState("");
    const [labelVisible, setLabelVisible] = useState(false);
    const [selectActionLink, setSelectActionLink] = useState("");
    const [actionLink, setActionLink] = useState("");
    const [isLandingPage, setIslandingPage] = useState(false);
    const [isClickRequired, setIsClickRequired] = useState(false);
    const [status, setStatus] = useState(false);

    //
    const [builtInIcons, setBuiltInIcons] = useState([]);
    const [array, setArray] = useState([]);
    const [contentMediaId, setContentMediaId] = useState(undefined);
    const [optionsInfo, setOptionsInfo] = useState({
        videoOptions: [],
        imageOptions: [],
        formOptions: [],
        quizOptions: []
    });

    const [hotspotObj, setHotspotObj] = useState();
    // const [hotspotObj, setHotspotObj] = useState({"cancel":"div","grid":[1,1],"defaultPosition":{"x":471,"y":7}});

    // instant changes
    if (props.isEdit !== false) {
        try {
            if (file) { document.getElementById("icon" + props.contentId).src = file };
            document.getElementById("label" + props.contentId).innerHTML = label;
        } catch (error) { }
    }

    function cancelForm() {
        window.location.reload(true);
    }

    function saveForm() {
        let startTime = (Number(showTimeMm * 60) + Number(showTimeSs));
        let endTime = (Number(hideTimeMm * 60) + Number(hideTimeSs));

        const saveHotspot = {
            id: contentMediaId === 'BLANK' ? '' : contentMediaId,
            label: label,
            // labelVisible: labelVisible ? 1 : 0,
            file: file,
            startTime: startTime,
            endTime: isClickRequired || pauseTimeEnable ? (startTime + 1) : endTime,
            actionLink: Number(selectActionLink) !== 2 && Number(selectActionLink) !== 5 ? JSON.stringify(actionLink) : actionLink,
            actionType: Number(selectActionLink),
            isPauseHotspot: pauseTimeEnable ? 1 : 0,
            hotspotPauseLength: (Number(pauseTimeSs) * 1000),
            isLandingPage: isLandingPage ? 1 : 0,
            isClickRequired: isClickRequired ? 1 : 0,
            status: startTime > 0 ? status ? 2 : 1 : 2,
            draggableProps: hotspotObj ? hotspotObj.draggableProps : "",
        }
        // check validation
        if (Number(startTime) <= Number(endTime) || isClickRequired || pauseTimeEnable) {
            if (label && label.length < 30) {
                if (file) {

                    if (String(selectActionLink) === "" || selectActionLink === 0) {
                        props.setHotspotData(saveHotspot);
                    } else if (String(selectActionLink) === "2") {
                        if (actionLink.startsWith("http") && validator.isURL(actionLink)) {
                            props.setHotspotData(saveHotspot);
                        } else {
                            alert("Please fill action valid link.");
                        }
                    } else if (String(selectActionLink) === "5") {
                        if (actionLink) {
                            props.setHotspotData(saveHotspot);
                        } else {
                            alert("Please fill action content");
                        }
                    } else if (String(selectActionLink) === "3") {
                        if (actionLink) {
                            props.setHotspotData(saveHotspot);
                        } else {
                            alert("Please select any video");
                        }
                    } else if (String(selectActionLink) === "6") {
                        if (actionLink) {
                            props.setHotspotData(saveHotspot);
                        } else {
                            alert("Please select any image");
                        }
                    } else if (String(selectActionLink) === "7") {
                        if (actionLink) {
                            props.setHotspotData(saveHotspot);
                        } else {
                            alert("Please select any form/survey");
                        }
                    } else if (String(selectActionLink) === "8") {
                        if (actionLink) {
                            props.setHotspotData(saveHotspot);
                        } else {
                            alert("Please select any quiz/contest");
                        }
                    }

                } else {
                    alert("Please select hotspot icon.");
                }
            } else {
                alert("Please fill Hotspot label and length of label should be less then 30 characters");
            }
        } else {
            alert("Enter valid start time and end time for hotspot");
        }
        // props.setHotspotData(saveHotspot)
    }

    async function fetchContentById(contentId) {
        console.log(contentId);
        // fetch the content media by id
        if (contentId !== 'BLANK') {
            let response = null;
            try {
                response = await HttpService.GET_BY_ID("/private/hotspot-setting/view", contentId);
            } catch (err) {
                isSessionExpire();
            }
            const savedResponse = response.data.object.hotspotSetting;
            console.log(savedResponse);
            setHotspotObj(savedResponse);

            // set fetch default value into states
            if (Number(savedResponse.startTime) > 59) {
                setShowTimeMm(Helper.beforeDecimal(Number(savedResponse.startTime / 60).toFixed(2)));
                setShowTimeSs(Helper.afterDecimal(Number(savedResponse.startTime / 60).toFixed(2)));
            } else {
                setShowTimeSs(savedResponse.startTime);
            }

            if (Number(savedResponse.endTime) > 59) {
                setHideTimeMm(Helper.beforeDecimal(String(Number(savedResponse.endTime / 60).toFixed(2))));
                setHideTimeSs(Helper.afterDecimal(String(Number(savedResponse.endTime / 60).toFixed(2))));
            } else {
                setHideTimeSs(savedResponse.endTime);
            }

            setFile(savedResponse.hotspotIconPath);
            setLabel(savedResponse.label);
            // setLabelVisible(savedResponse.labelVisible);
            setPauseTimeEnable(savedResponse.isPauseEnable === 1 ? true : false);
            setPauseTimeSs((savedResponse.pauseLength / 1000));
            setSelectActionLink(savedResponse.actionType);

            if (Number(savedResponse.actionType) !== 2 && Number(savedResponse.actionType) !== 5) {
                setActionLink(JSON.parse(savedResponse.actionContent));
            } else {
                setActionLink(savedResponse.actionContent);
            }

            setIslandingPage(savedResponse.isLandingPage === 1 ? true : false);
            setIsClickRequired(savedResponse.isClickRequired === 1 ? true : false);
            setStatus(savedResponse.status === 1 ? false : true);
        }
    }

    function handelActionLink(e) {
        setActionLink("");
        setSelectActionLink(e.target.value);
    }

    function changeStartTime(e) {
        const target = e.target;

        if (target.name === "showtime_mm") {
            setShowTimeMm(target.value);
        } else if (target.name === "showtime_ss") {
            setShowTimeSs(target.value);
        }
    }

    function pushEditorValue(value) {
        setActionLink(value);
    }

    function timeToSec(min, sec) {
        return Number((min * 60)) + Number(sec);
    }

    async function getInteractionsOptionsAndList() {
        const videoData = await getMainVideoListAndOptions(props.videoId);
        const imageData = await getImageListAndOptions(props.videoId);
        const formQuizData = await getFormQuizListAndOptions(props.videoId);
        setOptionsInfo({
            ...optionsInfo,
            ["videoOptions"]: videoData ? videoData.option : [],
            ["imageOptions"]: imageData ? imageData.option : [],
            ["formOptions"]: formQuizData ? formQuizData.filterData.options.formOptions : [],
            ["quizOptions"]: formQuizData ? formQuizData.filterData.options.quizOptions : []
        });
    }

    const onChangeSelect = (instance) => {
        setActionLink(instance);
    }

    useEffect(() => {
        var player = videojs('my-video');
        setPlayer(player);

        console.log(props.isEdit);
        if (props.isEdit !== false) {
            fetchContentById(props.contentId);
            setContentMediaId(props.contentId);
        }
        setBuiltInIcons(fileUtils.getIcons());
        setArray(fileUtils.getArray(10));
        getInteractionsOptionsAndList();
    }, []);

    useEffect(() => {
        let totalSec = timeToSec(showTimeMm, showTimeSs);
        if (player) {
            player.currentTime(totalSec);
        }

    }, [showTimeMm, showTimeSs]);

    return (
        <Fragment>
            <div id="edit_modal">
                <div className="edit_modal_inner">
                    <div className="edit_modal_title">
                        <h2>{props.isEdit !== false ? "Editing" : "Add"} Hotspot</h2>

                    </div>
                    <div className="edit_modal_fl">
                        <div className="edit_time">
                            <div className="editshowtime">
                                <label>Show Time</label>
                                <div className="showtime_inp">
                                    <input type="number" onChange={changeStartTime} value={showTimeMm} className="show_time" name="showtime_mm" min="0" max="59" placeholder="MM" />
                                    <input type="number" onChange={changeStartTime} value={showTimeSs} className="show_time" name="showtime_ss" min="0" max="59" placeholder="SS" />
                                </div>
                            </div>
                            <div className="edithidetime" style={{ display: !pauseTimeEnable && !isClickRequired ? "block" : "none" }}>
                                <label>Hide Time</label>
                                <div className="showtime_inp">
                                    <input type="number" onChange={(e) => setHideTimeMm(e.target.value)} value={hideTimeMm} className="hide_time" name="hidetime" min="0" max="59" placeholder="MM" />
                                    <input type="number" onChange={(e) => setHideTimeSs(e.target.value)} value={hideTimeSs} className="hide_time" name="hidetime" min="0" max="59" placeholder="SS" />
                                </div>
                            </div>
                        </div>
                        <div className="pauseFlex">
                            {
                                !isClickRequired ?
                                    <div className="edit_checkbox hotspot_edit_check">
                                        <div className="edit_hotspot_ck" style={{ paddingLeft: "20px" }}>
                                            <input type="checkbox" onChange={() => setPauseTimeEnable(!pauseTimeEnable)} checked={pauseTimeEnable} id="images_label" name="imglabel" />
                                            <label htmlFor="images"> Pause for Hotspot</label>
                                        </div>
                                        <div style={{ display: pauseTimeEnable === false ? "none" : "block" }}>
                                            <div className="edit_selectbox">
                                                <select onChange={(e) => setPauseTimeSs(e.target.value)} value={pauseTimeSs} className="config_poster_inp">
                                                    {
                                                        array.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item}>{item} second</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                !pauseTimeEnable ?
                                    <div className="edit_checkbox">
                                        <input type="checkbox" onChange={() => setIsClickRequired(!isClickRequired)} checked={isClickRequired} id="images_is_click_required" name="click_required" />
                                        <label>Click Required</label>
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="pauseFlex">
                            <div className="edit_checkbox">
                                <input type="checkbox" onChange={() => setStatus(!status)} checked={status} id="images_is_click_required" name="make_as_draft" />
                                <label>Make as Draft</label>
                            </div>
                        </div>

                        <div className="hotspot_label">
                            <input type="text" onChange={(e) => setLabel(e.target.value)} value={label} name="hotspot_label" placeholder="Enter Hotspot label" className="config_poster_inp" style={{ width: "100%" }} />
                        </div>

                        <div className="hotspot_position">
                            <div className="hotspot_position_1">
                                <h4>Hotspot Icon</h4>
                                <select onChange={(e) => setFile(e.target.value)} value={file} className="config_poster_inp">
                                    <option value="">Select Icon</option>
                                    {
                                        builtInIcons.map((object, index) => {
                                            return (
                                                <option key={index} value={object.iconUrl}>
                                                    {object.iconName}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div>
                            <div className="edit_selectbox_div">
                                <h4>Hotspot Action</h4>
                                <div className="edit_selectbox">
                                    <select onChange={handelActionLink} value={selectActionLink} className="config_poster_inp">
                                        <option value="">Select Action</option>
                                        <option value="2">Open Link</option>
                                        {/* <option value="4">Open Image</option> */}
                                        <option value="5">Show Message</option>
                                        <option value="3">Switch Video</option>
                                        <option value="6">Switch Image</option>
                                        <option value="7">Switch Form/Survey</option>
                                        <option value="8">Switch Quiz/Contest</option>
                                    </select>
                                </div>
                            </div>
                            {
                                String(selectActionLink) === "2" ?
                                    <div>
                                        <label htmlFor="actionURL" className="my-1" style={{ fontSize: "20px" }}>Action URL</label>
                                        <span style={{ display: "block" }}>Note : please enter valid url (https://www.domain.com or https://domain.com)</span>
                                        <div className="open_link img_input_txt" >
                                            <input className="config_poster_inp" onChange={(e) => setActionLink(e.target.value)} value={actionLink} type="text" name="open_link" placeholder="Enter URL (https://www.domain.com)" />
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                String(selectActionLink) === "5" ?
                                    <div className="show_message" >
                                        {/* <textarea onChange={(e) => setActionLink(e.target.value)} value={actionLink} rows={6} cols={29} name="show_message" placeholder="Enter HTML or Plain Text" ></textarea> */}
                                        <TextEditor height={250} width={450} content={actionLink} pushEditorValue={pushEditorValue} />
                                    </div>
                                    : null
                            }

                            {
                                String(selectActionLink) === "3" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Select Video"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionLink} // value  => { "value": value, "label": "label" }
                                            onChange={onChangeSelect}
                                            options={optionsInfo.videoOptions} />
                                    </div>
                                    : null
                            }

                            {
                                String(selectActionLink) === "6" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Select Image"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionLink}
                                            onChange={onChangeSelect}
                                            options={optionsInfo.imageOptions} />
                                    </div>
                                    : null
                            }

                            {
                                String(selectActionLink) === "7" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Form/Survey"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionLink}
                                            onChange={onChangeSelect}
                                            options={optionsInfo.formOptions} />
                                    </div>
                                    : null
                            }

                            {
                                String(selectActionLink) === "8" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Quiz/Contest"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionLink}
                                            onChange={onChangeSelect}
                                            options={optionsInfo.quizOptions} />
                                    </div>
                                    : null
                            }

                        </div>

                        {/* <div className="edit_checkbox">
                            <input type="checkbox" onChange={() => setIslandingPage(!isLandingPage)} checked={isLandingPage} id="images_is_landing_page" name="landing_page" />
                            <label>Make as Landing Page</label>
                        </div> */}

                        <div className="buttons_save">
                            <button className="btn_save" onClick={saveForm}>{contentMediaId && contentMediaId !== 'BLANK' ? "Update" : "Save"}</button>
                            <button className="btn_cancel" onClick={cancelForm} style={{ marginTop: "18px" }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default HotspotForm;