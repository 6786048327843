import { isSessionExpire } from '../../authentication/jwt/manageSession';
import HttpRequest from "../../../Common/httpServices/http.service";

async function deleteHotspot(data) {

    let status = window.confirm("Do you want to delete this hotspot");
    if (status === true) {
        if (data.id !== undefined && data.id !== null) {
            let response = null;
            try {
                response = await HttpRequest.DELETE_BY_ID("/private/hotspot-setting/delete", data.id);
            } catch (err) {
                isSessionExpire();
            }
            console.log(response);
            alert("Deleted Successfully");
            window.location.reload(true);
        } else {

        }
    }
}

async function deleteImage(data) {
    let status = window.confirm("Do you want to delete this image");
    if (status === true) {
        if (data.id !== undefined && data.id !== null) {
            let response = null;
            try {
                response = await HttpRequest.DELETE_BY_ID("/private/image", data.id);
                console.log(response);
            } catch (err) {
                isSessionExpire();
            }
            alert("Deleted Successfully");
            window.location.reload(true);
        } else {

        }
    }
}

async function deleteText(data) {
    let status = window.confirm("Do you want to delete this text");
    if (status === true) {
        if (data.id) {
            let response = null;
            try {
                response = await HttpRequest.DELETE_BY_ID("/private/text-setting/delete", data.id);
            } catch (err) {
                isSessionExpire();
            }
            console.log(response);
            alert("Deleted Successfully");
            window.location.reload(true);
        } else {

        }
    }
}

async function deleteForm(formId) {
    let status = window.confirm("Do you want to delete this form / Quiz");
    console.log(formId)


    if (status === true) {
        if (formId) {
            // console.log(contentMedia.id)
            let response = null;
            try {
                response = await HttpRequest.DELETE_BY_ID("/private/formsetting/delete", formId);
            } catch (err) {
                isSessionExpire();
            }
            console.log(response);
            alert("deleted form / Quiz Successfully");
            window.location.reload(true);
        } else {

        }
    }
}

async function manageSeekbarIcon(duration, list, video_id) {
    try {
        let element = document.getElementsByClassName('vjs-load-progress')[0];

        list.map(function (data, index) {
            // 
            if (Number(data.videoId) === Number(video_id) && Number(data.status) === 1) {
                const type = data.mediaType;
                const startTime = Number(data.startTime);

                const percent = ((startTime / duration) * 100) - 2;
                console.log(percent);

                let iconUniqueId = `seekbar_icon_${data.mediaType}_${data.id}`;

                if (startTime > 0) {
                    switch (type) {
                        case 'image':
                            if (Number(data.isBranching) !== 1) {
                                element.insertAdjacentHTML('beforebegin', "<img id='" + iconUniqueId + "' src=\"/assets/icon_images_2.png\" class=\"my-image\" style=\"padding:2px;left: " + percent + "%;\"/>");
                            }
                            break;
                        case 'hotspot':
                            element.insertAdjacentHTML('beforebegin', "<img id='" + iconUniqueId + "' src=\"/assets/icon_hotspot_2.png\" class=\"my-hotspot\" style=\"left: " + percent + "%;\"/>");
                            break;
                        case 'TXT':
                            element.insertAdjacentHTML('beforebegin', "<img id='" + iconUniqueId + "' src=\"/assets/icon_text_2.png\" class=\"my-text\" style=\"left: " + percent + "%;\"/>");
                            break;
                        case 'Form':
                            element.insertAdjacentHTML('beforebegin', "<img id='" + iconUniqueId + "' src=\"/assets/icon_form_1.png\" class=\"my-form\" style=\"left: " + percent + "%;\"/>");
                            break;
                        default:
                            break;
                    }
                }
            }
        });
    } catch (e) { }
}

async function getTemplateById(id) {
    if (id) {
        let response = null;
        try {
            response = await HttpRequest.GET_BY_ID("/private/video/template", id);
            if (response.data.status === "SUCCESS") {
                return response.data.object;
            }
        } catch (err) {
            isSessionExpire();
        }
    }
    return null;
}

function removeSeekbarIcons() {
    let seekbarIconImage = document.getElementsByClassName("my-image");
    let seekbarIconHotspot = document.getElementsByClassName("my-hotspot");
    let seekbarIconText = document.getElementsByClassName("my-text");
    let seekbarIconForm = document.getElementsByClassName("my-form");

    const length = [...seekbarIconImage, ...seekbarIconHotspot, ...seekbarIconText, ...seekbarIconForm].length;
    if (length > 0) {
        for (let i = 0; i < length; i++) {
            if (seekbarIconImage.length > 0) {
                seekbarIconImage[0].remove();
            }
            if (seekbarIconHotspot.length > 0) {
                seekbarIconHotspot[0].remove();
            }
            if (seekbarIconText.length > 0) {
                seekbarIconText[0].remove();
            }
            if (seekbarIconForm.length > 0) {
                seekbarIconForm[0].remove();
            }
        }

    }
}

async function getVideoTemplateList(videoId) {
    if (videoId) {
        let response = null;
        try {
            response = await HttpRequest.GET_BY_ID("/private/video/template/list", videoId);
            if (response.data.status === "SUCCESS") {
                return response.data.object;
            }
        } catch (err) {
            isSessionExpire();
        }
    }
    return null;
}

async function removeInteraction() {
    let renderHtmlConMedia = document.getElementsByClassName("draggable_child_div");
    let overlay = document.getElementsByClassName("parentOverlay");
    for (let i = 0; i < renderHtmlConMedia.length; i++) {
        let element = renderHtmlConMedia[i];
        element.style.display = "none";
        //
        if (overlay.length > 0) {
            overlay[0].style.height = 0;
        }
    }
}

async function getImageById(imageId) {
    if (imageId) {
        let response = null;
        try {
            response = await HttpRequest.GET_BY_ID("/private/image", imageId);
            if (response.data.status === "SUCCESS") {
                return response.data.object[0];
            }
        } catch (err) {
            isSessionExpire();
        }
    }
    return null;
}

async function getFormQuizById(formQuizId) {
    if (formQuizId) {
        let response = null;
        try {
            response = await HttpRequest.GET_BY_ID("/private/formSetting", formQuizId);
            if (response.data.status === "SUCCESS") {
                return response.data.object.formSetting;
            }
        } catch (err) {
            isSessionExpire();
        }
    }
    return null;
}

async function getVideoById(videoId) {
    if (videoId) {
        let response = null;
        try {
            response = await HttpRequest.GET("/private/main-video/" + videoId);
            if (response.data.status === "SUCCESS") {
                return response.data.object.mainVideo;
            }
        } catch (err) {
            isSessionExpire();
        }
    }
    return null;
}


export { deleteHotspot, deleteImage, deleteText, manageSeekbarIcon, getTemplateById, removeSeekbarIcons, deleteForm, getVideoTemplateList, removeInteraction, getImageById, getFormQuizById, getVideoById };