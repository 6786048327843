import { Fragment, useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import { useNavigate, useLocation } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './videoInteractionForm.css';
import Select from 'react-select';
import { videoTrackingOptions, typeOptions, isCustomOptions, trackingOptions } from './interactionOptions';
import HttpService from '../../../Common/httpServices/http.service';
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import Loader from "../../../Common/loader/loder";
import { Badge } from "react-bootstrap";

const Interaction_setting = () => {
    const [imageList, setImageList] = useState([]);
    const [hotspotList, setHotspotList] = useState([]);
    const [textList, setTextList] = useState([]);
    const [videoTemplateList, setVideoTemplateList] = useState([]);
    const [formQuizList, setFormQuizList] = useState([]);
    const [formStateRepleca, setFormStateRepleca] = useState([]);
    const [videoInteractionListA, setVideoInteractionListA] = useState([]);
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    // console.log('location ', location);

    const initialState = [
        {
            videoId: location.state.id,
            promptType: "",
            promptId: 0,
            isCustom: "",
            interactionTypeIds: ""
        }
    ];

    const [formState, setFormValues] = useState(initialState);

    const manageInputChange = (value, index, category) => {
        const updatedFormData = { ...formState[index], [category]: value };
        const newState = [...formState];
        newState[index] = updatedFormData;
        setFormValues(newState);
    }

    const manageMultiselectInputChange = (obj, index, category) => {
        let data = ''
        obj.map((value, index) => {
            if (!data) { data = value.value } else { data += ',' + value.value }
        })
        const updatedFormData = { ...formState[index], [category]: data };
        const newState = [...formState];
        newState[index] = updatedFormData;
        setFormValues(newState);
    }

    const manageMultiSelectValues = (value, optionsArr) => {
        if (!value) {
            return '';
        }

        let valuesArr = value.split(',');
        let data = valuesArr.map((value1, index) => {
            for (let i = 0; i < optionsArr.length; i++) {
                if (value1 === optionsArr[i].value) {
                    return optionsArr[i];
                }
            }
        })
        return data;
    }

    const manageSelectValues = (value, optionsArr) => {
        if (!value) {
            return '';
        }
        for (let i = 0; i < optionsArr.length; i++) {
            if (value === optionsArr[i].value) {
                return optionsArr[i];
            }
        }
    }

    const addMoreClicked = () => {
        const newState = [...formState];
        newState.push({
            videoId: location.state.id,
            promptType: "",
            promptId: 0,
            isCustom: "",
            interactionTypeIds: ""
        });
        setFormValues(newState);
    }

    function createList(formState) {
        let arrayObj = [];
        for (let i = 0; i < formState.length; i++) {
            const interactionTypeArray = formState[i].interactionType.split(",");
            const interactionTypeIdsArray = formState[i].interactionTypeIds.split(",");
            // const formStateReplecaArray = formStateRepleca[i].interactionType.split(",");

            for (let j = 0; j < interactionTypeArray.length; j++) {
                arrayObj.push({
                    id: interactionTypeArray.length > 1 ? Number(interactionTypeIdsArray[j]) : formState[i].id,
                    interactionType: interactionTypeArray[j],
                    isCustom: formState[i].isCustom,
                    promptId: formState[i].promptType === "video" ? location.state.id : formState[i].promptId,
                    promptType: formState[i].promptType,
                    videoId: formState[i].videoId
                });
            }
        }
        return arrayObj;
    }

    async function saveButtonClicked() {
        setLoader(true);
        
        for (let i = 0; i < formState.length; i++) {
            const element = formState[i];
            let isPromptIdValid = element.promptType === "video" ? false : Number(element.promptId) <= 0 ? true : false;

            if (!element.promptType || !String(element.isCustom) || !element.interactionType || isPromptIdValid) {
                alert("Please fill required fields for interaction " + (i + 1) + ".");
                setLoader(false);
                return;
            }
        }

        let arrayObj = createList(formState);
        try {
            const response = await HttpService.PUT_WITHOUT_ID("/private/video-interaction/update", arrayObj);
            if (response.data.status === "SUCCESS") {
                console.log(response);
                // navigate("/player", { state: { id: location.state.id } })
                // getVideoInteraction(location.state.id)
                alert("Saved Successfully");
                redirectTo();
            }

        } catch (err) {
            isSessionExpire();
        }
        setLoader(false);
    }

    function cancelButtonClicked() {
        // navigate("/player", { state: { id: location.state.id } })
    }

    const redirectTo = () => {
        window.location.href = `/player/${location.state.id}`;
    }

    async function getVideoInteraction(id) {
        const response = await HttpService.GET_BY_ID("/private/video-interaction", id);//@todo
        console.log(response);

        if (response.data.status === "SUCCESS" && response.data.object.videoInteractionList.length > 0) {
            setVideoInteractionListA(response.data.object.videoInteractionList);

            let interactionList = response.data.object.videoInteractionList;
            let formNewValue = [];
            let isNewObject = true;

            for (let i = 0; i < interactionList.length; i++) {
                // if (interactionList[i].promptType !== "template") {
                for (let j = 0; j < formNewValue.length; j++) {
                    if (interactionList[i].promptType === formNewValue[j].promptType && interactionList[i].promptId === formNewValue[j].promptId &&
                        interactionList[i].videoId === formNewValue[j].videoId && interactionList[i].isCustom === formNewValue[j].isCustom) {
                        isNewObject = false; // exists
                        if (interactionList[i].interactionType !== formNewValue[j].interactionType && interactionList[i].isCustom === 1) {
                            isNewObject = true;
                            break;
                        }
                        formNewValue[j].interactionTypeIds = formNewValue[j].interactionTypeIds.concat("," + interactionList[i].id); // for use in feature tests like delete
                        formNewValue[j].interactionType = formNewValue[j].interactionType.concat("," + interactionList[i].interactionType);
                        break;
                    } else {
                        isNewObject = true; // not exists
                    }
                }

                // Insert if new object not exists in array
                if (isNewObject) {
                    interactionList[i].interactionTypeIds = String(interactionList[i].id);
                    formNewValue.push(interactionList[i]);
                }
                // }
            }
            console.log(formNewValue);
            if (interactionList.length > 0) {
                setFormStateRepleca(formNewValue)
                setFormValues(formNewValue);
            }
        } else {
            setFormValues(initialState)
        }
    }

    const deleteIneraction = async (e, formData, index) => {
        var data = null;

        if (formData.id) {
            let status = window.confirm("Do you want to delete this video interation ?");

            if (status) {
                data = createList([formData]);
                // call api and delete perticluar interaction
                try {
                    for (let i = 0; i < data.length; i++) {
                        const response = await HttpService.DELETE(`/private/video-interaction/delete/${data[i].id}/${data[i].videoId}`);
                        console.log(response);
                        // if (response.data.status === "SUCCESS") {
                        // }
                    }
                    getVideoInteraction(location.state.id);
                    alert("Deleted Succesfully");
                } catch (err) {
                    isSessionExpire();
                }
            }

        } else {
            formState.splice(index, 1);
            const remaningData = [...formState];
            setFormValues(remaningData);
        }

    }

    const getImageListById = async (videoId) => {
        let response = null;
        try {
            response = await HttpService.GET_BY_ID("/private/image/lists", videoId);
            if (response.data.status === "SUCCESS") {
                setImageList(response.data.object.imageList);
                // console.log(response.data.object.imageList);
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    const getHotspotListById = async (videoId) => {
        let response = null;
        try {
            response = await HttpService.GET_BY_ID("/private/hotspot-setting/list", videoId);
            if (response.data.status === "SUCCESS") {
                setHotspotList(response.data.object);
                // console.log(response.data.object);
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    const getTextListById = async (videoId) => {
        let response = null;
        try {
            response = await HttpService.GET_BY_ID("/private/text-setting/list", videoId);
            if (response.data.status === "SUCCESS") {
                setTextList(response.data.object.textSettingList);
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    const getVideoTemplateListById = async (videoId) => {
        let response = null;
        try {
            response = await HttpService.GET_BY_ID("/private/video/template/list", videoId);
            if (response.data.status === "SUCCESS") {
                setVideoTemplateList(response.data.object);
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    const getFormQuizListById = async (videoId) => {
        
        let response = null;
        try {
            response = await HttpService.GET_BY_ID("/private/formSetting/list", videoId);
            if (response.data.status === "SUCCESS") {
                setFormQuizList(response.data.object.formSettingList);
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    useEffect(() => {
        let id = location.state.id;
        getImageListById(id);
        getHotspotListById(id);
        getTextListById(id);
        getVideoInteraction(id);
        getVideoTemplateListById(id);
        getFormQuizListById(id);
    }, []);

    console.log(location.state.id);
    console.log(formState);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <Container>
                <div className="vd_config">
                    <Col md={12}> <h2>Tracking Events <Badge bg="primary">{location.state.id}</Badge></h2></Col>
                    <div className="hotspot_position_1">
                        {
                            formState.map((formData, index) => {
                                if (formData.promptType !== "template") {
                                    return (
                                        <div className="video_interaction" key={index}>
                                            <Row>
                                                <Col md={3}><h4>Type *</h4></Col>
                                                <Col md={9}>
                                                    <Select
                                                        name="promptType"
                                                        // value={formData.promptType}
                                                        value={manageSelectValues(formData.promptType, typeOptions)}
                                                        onChange={(e) => {
                                                            manageInputChange(e.value, index, 'promptType');
                                                        }}
                                                        options={typeOptions}
                                                        classNamePrefix="select"
                                                    />
                                                </Col>
                                            </Row>
                                            {
                                                formData.promptType != "video" && formData.promptType ?
                                                    <Row>
                                                        <Col md={3}><h4>Prompt Id *</h4></Col>
                                                        <Col md={9}>
                                                            <select
                                                                value={formData.promptId}
                                                                onChange={(e) => {
                                                                    manageInputChange(e.target.value, index, 'promptId');
                                                                }}>
                                                                <option value={""}>Select</option>
                                                                {
                                                                    formData.promptType === "image" ?
                                                                        imageList.length > 0 ?
                                                                            imageList.map((imageObj, index) => {
                                                                                return <option key={index} value={imageObj.id}>{imageObj.label}</option>
                                                                            })
                                                                            : <option value={""}>No Data</option>
                                                                        : formData.promptType === "hotspot" ?
                                                                            hotspotList.length > 0 ?
                                                                                hotspotList.map((hotspotObj, index) => {
                                                                                    return <option key={index} value={hotspotObj.id}>{hotspotObj.label}</option>
                                                                                })
                                                                                : <option value={""}>No Data</option>
                                                                            : formData.promptType === "TXT" ?
                                                                                textList.length > 0 ?
                                                                                    textList.map((textObj, index) => {
                                                                                        return <option key={index} value={textObj.id}>{textObj.label}</option>
                                                                                    })
                                                                                    : <option value={""}>No Data</option>
                                                                                : formData.promptType === "template" ?
                                                                                    videoTemplateList.map((videoTemplateObj, index) => {
                                                                                        return <option key={index} value={videoTemplateObj.id}>{videoTemplateObj.templateName}</option>
                                                                                    })
                                                                                    : formData.promptType === "Form" ?
                                                                                        formQuizList.map((formQuizObj, index) => {
                                                                                            return <option key={index} value={formQuizObj.id}>{formQuizObj.formTitle}</option>
                                                                                        })
                                                                                        : null
                                                                }

                                                            </select>
                                                            {/* formQuizList */}
                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                            <Row>
                                                <Col md={3}><h4>Event Type *</h4></Col>
                                                <Col md={9}>
                                                    <Select
                                                        name="isCustom"
                                                        // value={formData.isCustom}
                                                        value={manageSelectValues(String(formData.isCustom), isCustomOptions)}
                                                        onChange={(e) => {
                                                            manageInputChange(e.value, index, 'isCustom');
                                                        }}
                                                        options={isCustomOptions}
                                                        classNamePrefix="select"
                                                    />
                                                </Col>
                                            </Row>
                                            {
                                                formData.promptType === 'video' && String(formData.isCustom) === '0' ?
                                                    <Row>
                                                        <Col md={3}><h4>Video Tracking Options *</h4></Col>
                                                        <Col md={9}>
                                                            <Select
                                                                // isSearchable={false}
                                                                name="interactionType"
                                                                // value={formData.interactionType}
                                                                value={manageMultiSelectValues(formData.interactionType, videoTrackingOptions)}
                                                                onChange={(e) => {
                                                                    manageMultiselectInputChange(e, index, 'interactionType');
                                                                }}
                                                                isMulti
                                                                options={videoTrackingOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />

                                                            {
                                                                formData.id ?
                                                                    videoInteractionListA.map((object, index) => {
                                                                        return object.promptType === "video" && String(object.isCustom) !== "1" ?
                                                                            <Row key={index}>
                                                                                <Col >Type : {object.interactionType.split(",")[0]}</Col>
                                                                                <Col>Id: {object.id}</Col>
                                                                            </Row>
                                                                            : null
                                                                    })
                                                                    : null
                                                            }

                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                            {
                                                formData.promptType === 'image' && String(formData.isCustom) === '0' ?
                                                    <Row>
                                                        <Col md={3}><h4>Image Tracking Options</h4></Col>
                                                        <Col md={9}>
                                                            <Select
                                                                name="interactionType"
                                                                // value={formData.interactionType}
                                                                // value={[{ label: "Click", value: "CLICK" }, { label: "Impression", value: "IMPRESSION" }]}
                                                                value={manageMultiSelectValues(formData.interactionType, trackingOptions)}
                                                                onChange={(e) => {
                                                                    manageMultiselectInputChange(e, index, 'interactionType');
                                                                }}
                                                                isMulti
                                                                options={trackingOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />

                                                            {
                                                                formData.id ?
                                                                    videoInteractionListA.map((object, index) => {
                                                                        if (Number(formData.promptId) === Number(object.promptId)) {
                                                                            return object.promptType === "image" && String(object.isCustom) !== "1" ?
                                                                                <Row key={index}>
                                                                                    <Col >Type : {object.interactionType.split(",")[0]}</Col>
                                                                                    <Col>Id: {object.id}</Col>
                                                                                </Row>
                                                                                : null
                                                                        }
                                                                    })
                                                                    : null
                                                            }

                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                            {
                                                formData.promptType === 'hotspot' && String(formData.isCustom) === '0' ?
                                                    <Row>
                                                        <Col md={3}><h4>Hotspot Tracking Options</h4></Col>
                                                        <Col md={9}>
                                                            <Select
                                                                name="interactionType"
                                                                // value={formData.interactionType}
                                                                value={manageMultiSelectValues(formData.interactionType, trackingOptions)}
                                                                onChange={(e) => {
                                                                    manageMultiselectInputChange(e, index, 'interactionType');
                                                                }}
                                                                isMulti
                                                                options={trackingOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />

                                                            {
                                                                formData.id ?
                                                                    videoInteractionListA.map((object, index) => {
                                                                        if (Number(formData.promptId) === Number(object.promptId)) {
                                                                            return object.promptType === "hotspot" && String(object.isCustom) !== "1" ?
                                                                                <Row key={index}>
                                                                                    <Col >Type : {object.interactionType.split(",")[0]}</Col>
                                                                                    <Col>Id: {object.id}</Col>
                                                                                </Row>
                                                                                : null
                                                                        }
                                                                    })
                                                                    : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                            {
                                                formData.promptType === 'TXT' && String(formData.isCustom) === '0' ?
                                                    <Row>
                                                        <Col md={3}><h4>Text Tracking Options</h4></Col>
                                                        <Col md={9}>
                                                            <Select
                                                                name="interactionType"
                                                                // value={formData.interactionType}
                                                                value={manageMultiSelectValues(formData.interactionType, trackingOptions)}
                                                                onChange={(e) => {
                                                                    manageMultiselectInputChange(e, index, 'interactionType');
                                                                }}
                                                                isMulti
                                                                options={trackingOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />

                                                            {
                                                                formData.id ?
                                                                    videoInteractionListA.map((object, index) => {
                                                                        if (Number(formData.promptId) === Number(object.promptId)) {
                                                                            return object.promptType === "TXT" && String(object.isCustom) !== "1" ?
                                                                                <Row key={index}>
                                                                                    <Col >Type : {object.interactionType.split(",")[0]}</Col>
                                                                                    <Col>Id: {object.id}</Col>
                                                                                </Row>
                                                                                : null
                                                                        }
                                                                    })
                                                                    : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                            {
                                                formData.promptType === 'popUp' && String(formData.isCustom) === '0' ?
                                                    <Row>
                                                        <Col md={3}><h4>PopUp Tracking Options</h4></Col>
                                                        <Col md={9}>
                                                            <Select
                                                                name="interactionType"
                                                                // value={formData.interactionType}
                                                                value={manageMultiSelectValues(formData.interactionType, trackingOptions)}
                                                                onChange={(e) => {
                                                                    manageMultiselectInputChange(e, index, 'interactionType');
                                                                }}
                                                                isMulti
                                                                options={trackingOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />

                                                            {
                                                                formData.id ?
                                                                    videoInteractionListA.map((object, index) => {
                                                                        if (Number(formData.promptId) === Number(object.promptId)) {
                                                                            return object.promptType === "popUp" && String(object.isCustom) !== "1" ?
                                                                                <Row key={index}>
                                                                                    <Col >Type : {object.interactionType.split(",")[0]}</Col>
                                                                                    <Col>Id: {object.id}</Col>
                                                                                </Row>
                                                                                : null
                                                                        }
                                                                    })
                                                                    : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                            {
                                                formData.promptType === 'template' && String(formData.isCustom) === '0' ?
                                                    <Row>
                                                        <Col md={3}><h4>Template Tracking Options</h4></Col>
                                                        <Col md={9}>
                                                            <Select
                                                                name="interactionType"
                                                                // value={formData.interactionType}
                                                                value={manageMultiSelectValues(formData.interactionType, trackingOptions)}
                                                                onChange={(e) => {
                                                                    manageMultiselectInputChange(e, index, 'interactionType');
                                                                }}
                                                                isMulti
                                                                options={trackingOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />

                                                            {
                                                                formData.id ?
                                                                    videoInteractionListA.map((object, index) => {
                                                                        if (Number(formData.promptId) === Number(object.promptId)) {
                                                                            return object.promptType === "template" && String(object.isCustom) !== "1" ?
                                                                                <Row key={index}>
                                                                                    <Col >Type : {object.interactionType.split(",")[0]}</Col>
                                                                                    <Col>Id: {object.id}</Col>
                                                                                </Row>
                                                                                : null
                                                                        }
                                                                    })
                                                                    : null
                                                            }

                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                            {
                                                formData.promptType === 'Form' && String(formData.isCustom) === '0' ?
                                                    <Row>
                                                        <Col md={3}><h4>Form Quiz Tracking Options</h4></Col>
                                                        <Col md={9}>
                                                            <Select
                                                                name="interactionType"
                                                                // value={formData.interactionType}
                                                                value={manageMultiSelectValues(formData.interactionType, trackingOptions)}
                                                                onChange={(e) => {
                                                                    manageMultiselectInputChange(e, index, 'interactionType');
                                                                }}
                                                                isMulti
                                                                options={trackingOptions}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />

                                                            {
                                                                formData.id ?
                                                                    videoInteractionListA.map((object, index) => {
                                                                        if (Number(formData.promptId) === Number(object.promptId)) {
                                                                            return object.promptType === "Form" && String(object.isCustom) !== "1" ?
                                                                                <Row key={index}>
                                                                                    <Col >Type : {object.interactionType.split(",")[0]}</Col>
                                                                                    <Col>Id: {object.id}</Col>
                                                                                </Row>
                                                                                : null
                                                                        }
                                                                    })
                                                                    : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                            {
                                                String(formData.isCustom) === '1' ?
                                                    <Row>
                                                        <Col md={3}><h4>Custom *</h4></Col>
                                                        <Col md={9}>
                                                            <input
                                                                name='interactionType'
                                                                value={formData.interactionType}
                                                                onChange={(e) => {
                                                                    manageInputChange(e.target.value, index, 'interactionType');
                                                                }}
                                                                type="text"
                                                                id="label_txt"
                                                                className="config_poster_inp"
                                                                placeholder="Enter Text here" />

                                                            <Row>
                                                                {/* <Col >Type : {formData.interactionType}</Col> */}
                                                                <Col>Id: {formData.id}</Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                            {
                                                index !== 0 || formData.promptId ?
                                                    <button className="btn btn-danger" style={{ width: "100px" }} onClick={(e) => deleteIneraction(e, formData, index)}>delete</button>
                                                    : null
                                            }
                                        </div>
                                    )

                                }

                            })
                        }
                        <Row>
                            <Col md={12}>
                                <button className="btn_save" onClick={addMoreClicked}>Add More</button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <button className="btn_save" onClick={saveButtonClicked}>Save</button>
                                <button className="btn_cancel" onClick={redirectTo}>Cancel</button>
                            </Col>
                        </Row>
                    </div>

                </div>
            </Container>
        </Fragment>
    )
}

export default Interaction_setting;
