import React, { Fragment, useEffect, useState } from 'react';
import { getVideoSizes, extractVideoSize } from '../video-cloning-helper/videoCloningSize';
import HttpRequest from '../../../Common/httpServices/http.service';
import "./videoCloning.css";
import Loader from '../../../Common/loader/loder';

const VideoCloning = (props) => {
    const [videoSizes, setVideoSize] = useState(getVideoSizes);
    const [loader, setLoader] = useState(false);
    const [selectedSize, setSelectedSize] = useState({
        videoSize: ""
    });
    const [cloneRequest, setCloneRequest] = useState({
        label: "",
        playerWidth: "",
        playerHeight: ""
    });

    function redireactTo(url) {
        window.location.href = url;
    }

    const cloneVideo = async () => {
        let selectedVideoSize = selectedSize.videoSize;
        if (selectedVideoSize) {
            setLoader(true)
            let videoSize = extractVideoSize(selectedVideoSize);

            // set data to cloneRequest
            cloneRequest.label = `${props.videoName}_${selectedVideoSize}`;
            cloneRequest.playerWidth = videoSize.width;
            cloneRequest.playerHeight = videoSize.height;

            if (cloneRequest.label) {
                // call Api and send request
                let response = await HttpRequest.POST(`/private/clone/main-video/${props.videoId}`, cloneRequest);
                let data = response.data;

                // check response status code
                if (data.status == "SUCCESS") {
                    alert(`Video size (${selectedSize.videoSize}) clone successfully.`);
                    redireactTo(`/player/${data.object.mainVideo.id}`);
                } else if (data.status == "DUPLICATE_ENTRY") {
                    alert(`Video size (${selectedSize.videoSize}) already clone.`);
                } else if (data.status == "FAILED") {
                    alert(`Id not valid / id not found`);
                } else {
                    // alert("");
                }
                setLoader(false);
            }
        } else {
            alert("please select a size for video clone!!!");
        }
    }

    const onChangeValue = (e) => {
        let target = e.target;
        setSelectedSize({
            ...selectedSize,
            [target.name]: target.value
        })
    }

    useEffect(() => {

    }, []);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <div>
                <select id="videoSizes" className="video-sizes mr-2" name="videoSize" onChange={onChangeValue} >
                    <option value={""} selected>Select Clone Size</option>
                    {
                        videoSizes.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>
                        })
                    }
                </select>
                <button onClick={cloneVideo} className='btn btn-success btn-sm m-2'>Clone Video</button>
            </div>
        </Fragment>
    );
}

export default VideoCloning;
