const videoTrackingOptions = [
    { value: 'firstQuartile', label: 'firstQuartile' },
    { value: 'midpoint', label: 'midpoint' },
    { value: 'thirdQuartile', label: 'thirdQuartile' },
    { value: 'complete', label: 'complete' },
    { value: 'resume', label: 'resume' },
    { value: 'pause', label: 'pause' },
    { value: 'mute', label: 'mute' },
    { value: 'unmute', label: 'unmute' },
    { value: 'start', label: 'start' },
    { value: 'watchTime', label: 'watchTime' },
    { value: 'click', label: 'click' },
    { value: 'impression', label: 'impression' }
];

const typeOptions = [
    { value: 'video', label: 'Video' },
    { value: 'image', label: 'Image' },
    { value: 'hotspot', label: 'Hotspot' },
    { value: 'TXT', label: 'Text' },
    // { value: 'popUp', label: 'PopUp' },
    // { value: 'template', label: 'HTML Template' },
    { value: 'Form', label: 'Form Quiz' }
];

const isCustomOptions = [
    { value: '0', label: 'Predefined' },
    { value: '1', label: 'Custom' }
];

const trackingOptions = [
    { value: 'CLICK', label: 'Click' },
    { value: 'IMPRESSION', label: 'Impression' }
];

export { videoTrackingOptions, typeOptions, isCustomOptions, trackingOptions };