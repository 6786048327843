class FileProcess {
    ObjArray = [];

    // parent method 
    manageUploads = async (files) => {
        // get selected files from user
        const filesLength = files.length;
        if (filesLength === undefined) {
            return {
                name: files.name,
                size: files.size,
                type: files.type,
                lastModified: files.lastModified,
                lastModifiedDate: files.lastModifiedDate,
                base64EncodedImg: await this.convertBase64(files)
            };
        } else {
            for (let i = 0; i < filesLength; i++) {
                this.ObjArray.push({
                    name: files[i].name,
                    size: files[i].size,
                    type: files[i].type,
                    lastModified: files[i].lastModified,
                    lastModifiedDate: files[i].lastModifiedDate,
                    base64EncodedImg: await this.convertBase64(files[i])
                });
            }
        }

        return this.ObjArray;
    }

    // child method 
    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };

        });
    };



}

export default new FileProcess();