import React, { Fragment, useState, useEffect } from "react";
import './videoBranchingForm.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FileUploader } from "react-drag-drop-files";
import fileUtils from "../../../utils/file-utils/fileUtils";
import fileProcess from "../../../Common/Image_Processing/fileProcess";
import HttpService from '../../../Common/httpServices/http.service';
import { Tab, Tabs } from "react-bootstrap";

import { useNavigate, useLocation } from "react-router-dom";
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import Loader from "../../../Common/loader/loder";
import Select from 'react-select';

var video_id;

const VideoBranchingForm = (props) => {

    const [mainVideoList, setMainVideoList] = useState([]);
    const [templateList, setTemplateList] = useState();
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    console.log('location ', location);

    try {
        if (location.state.id) {
            video_id = location.state.id;
        } else {
            // navigate("/");
        }
    } catch (err) {
        // navigate("/");
    }

    const initialData = [
        {
            id: '',
            videoId: props.videoId,
            mappingId: '',
            mappingType: '',
            mappingLabel: '',
            mappingIcon: '',
            mappingOrder: '',
            replayVideoTime: '',
            status: '',
            branchingTheme: ''
        }
    ];

    const [formState, setFormDataValue] = useState(initialData);
    const [files, setFiles] = useState("");

    const manageInputChange = (value, index, category) => {
        const updatedFormData = { ...formState[index], [category]: value };
        const newData = [...formState];
        newData[index] = updatedFormData;
        setFormDataValue(newData);
    }

    const addMoreClicked = () => {
        const newData = [...formState];
        newData.push({
            id: '',
            videoId: props.videoId,
            mappingId: '',
            mappingType: '',
            mappingLabel: '',
            mappingIcon: '',
            mappingOrder: '',
            replayVideoTime: '',
            status: ''
        });
        setFormDataValue(newData);
    }

    async function onChangeUploadedVideos(file) {
        // console.log('file obj')
        // console.log(file);
        // console.log(await fileProcess.manageUploads(file));
        setFiles(await fileProcess.manageUploads(file));
        // setFiles(file);
    }

    function cancelButtonClicked() {
        // navigate("/player", { state: { id: video_id } })
    }

    const redirectTo = () => {
        window.location.href = `/player/${video_id}`;
    }

    async function saveButtonClicked() {
        setLoader(true);

        for (let i = 0; i < formState.length; i++) {
            const element = formState[i];

            // Check Validations
            if (!element.mappingLabel || !element.mappingIcon || !String(element.mappingType) || !element.mappingId) {
                alert("Please fill required fields for Branching Video/Template " + (i + 1) + "");
                setLoader(false);
                return;
            }
            if (element.mappingLabel.length > 12) {
                alert("Label must be 12 character's Branching Video/Template " + (i + 1) + "");
                setLoader(false);
                return;
            }

            // push data into state
            const draggableProps = element.draggableProps ? JSON.parse(element.draggableProps) : null
            element.draggableProps = JSON.stringify({
                // height: ctaElement.offsetHeight,
                // width: ctaElement.offsetWidth,
                cancel: "div",
                grid: [1, 1],
                defaultPosition: draggableProps ?
                    props.deltaPosition.x >= 0 || props.deltaPosition.y >= 0 ?
                        props.deltaPosition
                        : draggableProps.defaultPosition.x >= 0 || draggableProps.defaultPosition.y >= 0 ?
                            draggableProps.defaultPosition
                            : { x: 200, y: 200 }
                    : { x: 200, y: 200 }
            });
        }

        

        try {
            let response = null;
            try {
                response = await HttpService.PUT_WITHOUT_ID("/private/branch-mapping/update", formState);
                console.log(response);
            } catch (err) {
                isSessionExpire();
            }

            if (response.data.status === "SUCCESS") {
                // getVideoBranching();
                alert("Saved Successfully");
                // navigate("/player", { state: { id: video_id } })
                redirectTo();
            }
        } catch (err) { }
        setLoader(false);
    }

    async function getVideoBranching() {
        try {
            const id = video_id;
            let response = null;
            try {
                response = await HttpService.GET("/private/" + id + "/branch-mapping");
            } catch (err) {
                isSessionExpire();
            }

            if (response.data.status === "SUCCESS") {
                console.log(response);
                setFormDataValue(response.data.object.branchMappingList);

                let branchingMapping = response.data.object.branchMappingList;
                // showBranching(branchingMapping);
            }
        } catch (err) {

        }
    }

    function showBranching(branchingMapping) {
        
        let container = document.getElementsByClassName("branching_icons");
        let ele = document.getElementsByClassName("draggable_child_container");

        let draggablePropsObj = "";
        try {
            if (branchingMapping[0].draggableProps) {
                draggablePropsObj = JSON.parse(branchingMapping[0].draggableProps);
            }
        } catch (error) { }

        if (container.length > 0 && draggablePropsObj) {
            ele[0].style.transform = `translate(${draggablePropsObj.defaultPosition.x}px, ${draggablePropsObj.defaultPosition.y}px)`;
            container[0].innerHTML = "";
        }

        branchingMapping.map((object, index) => {
            if (
                (object.mappingLabel && object.mappingLabel !== '') ||
                (object.mappingIcon && object.mappingIcon !== '')
            ) {
                const div = document.createElement("div"),
                    img = document.createElement("img"),
                    span = document.createElement("span");

                img.src = object.mappingIcon;
                img.alt = "image_" + object.id;
                span.innerHTML = object.mappingLabel;
                div.classList.add('first_video_btns');
                div.style.zIndex = -1;
                div.appendChild(img);
                div.appendChild(span);

                if (container.length > 0) {
                    container[0].appendChild(div);
                }
            }
        })

    }

    async function getMainVideoList() {
        let response = null;
        try {
            response = await HttpService.GET(`/private/main-video/list?page-number=${0}&page-size=${10000}&video-name=${""}`);
        } catch (err) {
            isSessionExpire();
        }

        if (response.data.status === "SUCCESS") {
            setMainVideoList(response.data.object.content)
        } else {
            console.log("main video not found");
        }
    }

    async function getTemplateList() {
        // 
        let response = null;
        try {
            response = await HttpService.GET_BY_ID("/private/video/template/list", video_id);
        } catch (err) {
            isSessionExpire();
        }

        if (response.data.status === "SUCCESS") {
            // console.log(response.data.object);
            setTemplateList(response.data.object)
        } else {
            console.log("tamplet list not found");
        }
    }

    async function onChangeUploadedImages(file, index) {
        // 
        const base64PosterImg = await fileProcess.manageUploads(file);
        setFiles(base64PosterImg.base64EncodedImg);
        manageInputChange(base64PosterImg.base64EncodedImg, index, 'mappingIcon');
    }

    const onChnagePosterUrl = (e, index) => {
        setFiles(e.target.value)
        manageInputChange(e.target.value, index, 'mappingIcon');
    }

    const deleteBranching = async (object, index) => {
        let status = window.confirm("Do you want to delete this branching Video/Template ?");
        if (status === true) {
            setLoader(true);
            if (object.id) {
                let response = null;
                try {
                    response = await HttpService.DELETE_BY_ID("/private/branch-mapping/delete", `${object.id}/${object.videoId}`);
                } catch (err) {
                    isSessionExpire();
                }

                if (response.data.status === "SUCCESS") {
                    console.log(response);
                    getVideoBranching(); // update
                    setFormDataValue(initialData);
                    alert("Deleted Succesfully")
                } else {
                    console.log("branching not delete !!!");
                }
            } else {
                // formState
                for (let i = 0; i < formState.length; i++) {
                    if (i === index) {
                        formState.splice(i, 1);
                    }
                }
                const remaningData = [...formState];
                setFormDataValue(remaningData)
            }
        }
        setLoader(false);
    }

    useEffect(() => {
        video_id = props.videoId;
        getVideoBranching();
        getMainVideoList();
        getTemplateList();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            var element = document.getElementById("toggal_branching_icons");
            if (props.deltaPosition.x >= 0 || props.deltaPosition.y >= 0) {
                formState[0].draggableProps = `{"cancel":"div","grid":[1,1],"defaultPosition":{"x":${props.deltaPosition.x},"y":${props.deltaPosition.y}}}`
            }

            showBranching(formState);
            if (element)
                if (Number(formState[0].branchingTheme) === 1) {
                    element.classList.add("branchingVertical");
                } else if (Number(formState[0].branchingTheme) === 0) {
                    element.classList.remove("branchingVertical");
                }
        }, 600);
    }, [formState]);

    console.log(formState);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <div id="edit_modal">
                <div className="edit_modal_inner">
                    <Container>
                        <div className="vd_config">
                            <Row style={{ flexFlow: "row", justifyContent: "space-between" }}>
                                <Col md={12} style={{ width: "auto" }}> <h2>Video Branching Setting</h2> </Col>
                                {/* <Col md={12} style={{ width: "auto" }}> <h2 >Video id : {video_id}</h2> </Col> */}
                            </Row>
                            {/* <Col md={12}> <h2>Video Branching Setting</h2></Col> */}



                            <div className="hotspot_position_1">
                                {
                                    formState.map((formData, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="additional_fields">
                                                    {
                                                        index === 0 ?
                                                            <div>
                                                                <span>Theme</span>
                                                                <select
                                                                    value={formData.branchingTheme}
                                                                    onChange={(e) => {
                                                                        manageInputChange(e.target.value, index, 'branchingTheme');
                                                                    }}>
                                                                    <option value="0">Horizontal</option>
                                                                    <option value="1">Vertical</option>
                                                                </select>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="video_branching" key={index}>
                                                    <Row>
                                                        <Col md={3}><h4>Type *</h4></Col>
                                                        <Col md={9}>
                                                            <select
                                                                value={formData.mappingType}
                                                                onChange={(e) => {
                                                                    manageInputChange(e.target.value, index, 'mappingType');
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="0">Video</option>
                                                                <option value="1">HTML Template</option>
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        String(formData.mappingType) === '0' ?
                                                            <Row>
                                                                <Col md={3}><h4>Select Video *</h4></Col>
                                                                <Col md={9}>
                                                                    <select
                                                                        value={formData.mappingId}
                                                                        onChange={(e) => { manageInputChange(e.target.value, index, 'mappingId'); }}>
                                                                        <option value="select">Select</option>
                                                                        {
                                                                            mainVideoList && mainVideoList !== [] && mainVideoList.length > 1 ?
                                                                                mainVideoList.map((object, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            {
                                                                                                String(video_id) === String(object.id) ? null :
                                                                                                    <option value={object.id}>{object.label}</option>
                                                                                            }
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                                : <option disabled>no data</option>
                                                                        }
                                                                    </select>
                                                                </Col>
                                                            </Row>
                                                            : null

                                                    }
                                                    {
                                                        String(formData.mappingType) === '1' ?
                                                            <Row>
                                                                <Col md={3}><h4>Select HTML Template *</h4></Col>
                                                                <Col md={9}>
                                                                    <select
                                                                        value={formData.mappingId}
                                                                        onChange={(e) => {
                                                                            manageInputChange(e.target.value, index, 'mappingId');
                                                                        }}
                                                                    >
                                                                        <option value="select">Select</option>
                                                                        {
                                                                            templateList ?
                                                                                templateList.map((object, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <option value={object.id}>{object.templateName}</option>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                                : <option disabled>no data</option>
                                                                        }
                                                                    </select>
                                                                </Col>
                                                            </Row>
                                                            : null

                                                    }
                                                    <Row>
                                                        <Col md={3}><h4>Label *</h4></Col>
                                                        <Col md={9}>
                                                            <input
                                                                type="text"
                                                                value={formData.mappingLabel}
                                                                onChange={(e) => {
                                                                    manageInputChange(e.target.value, index, 'mappingLabel');
                                                                }}
                                                                id="label_txt"
                                                                className="config_poster_inp"
                                                                placeholder="Enter Label here" />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={3}><h4>Upload Icon *</h4></Col>
                                                        <Col md={9}>
                                                            <Tabs defaultActiveKey={formData.mappingIcon ? formData.mappingIcon.startsWith("http") ? `enter_url` : `upload_new_icon` : `enter_url`} id="video_tabs" className="mb-3 tabs_news">
                                                                <Tab eventKey={`upload_new_icon}`} title={`Upload Icon`}>
                                                                    <FileUploader multiple={false} handleChange={(file) => onChangeUploadedImages(file, index)} name="config_icon-files" id="config_icon-files" types={fileUtils.getFileTypes("image")} />
                                                                    <p>{files ? <span>file selected.</span> : "select icon image for upload."}</p>
                                                                </Tab>
                                                                <Tab eventKey="enter_url" title="Enter URL">
                                                                    <div className="enter_url">
                                                                        <input value={formData.mappingIcon} onChange={(e) => onChnagePosterUrl(e, index)} name="iconImagePath" type="text" id="icon_img" className="config_poster_inp" placeholder="Enter Icon Url" />
                                                                    </div>
                                                                </Tab>
                                                            </Tabs>
                                                            <div className="pb-4">
                                                                {formData.mappingIcon ?
                                                                    <img className="icon_view" src={formData.mappingIcon} width="100" alt="icon_view" />
                                                                    : <img width="100" src={"https://storage.googleapis.com/interactive-truevdo/add_poster_image_dir/AOvTVQf3_20-01-2023_1674192406481.jpeg"} />
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={3}><h4>Priority</h4></Col>
                                                        <Col md={9}>
                                                            <input
                                                                type="text"
                                                                value={formData.mappingOrder}
                                                                onChange={(e) => {
                                                                    manageInputChange(e.target.value, index, 'mappingOrder');
                                                                }}
                                                                id="priority_txt"
                                                                className="config_poster_inp"
                                                                placeholder="Enter Priority here" />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={3}><h4>Replay Time (In sec)</h4></Col>
                                                        <Col md={9}>
                                                            <input
                                                                type="text"
                                                                value={formData.replayVideoTime}
                                                                onChange={(e) => {
                                                                    manageInputChange(e.target.value, index, 'replayVideoTime');
                                                                }}
                                                                id="priority_txt"
                                                                className="config_poster_inp"
                                                                placeholder="Enter Replay Time here" />
                                                        </Col>
                                                    </Row>
                                                    {
                                                        index !== 0 || formData.mappingLabel ?
                                                            <Row className="m-2">
                                                                <Col>
                                                                    <button className="btn btn-danger" onClick={() => deleteBranching(formData, index)}>Delete</button>
                                                                </Col>
                                                            </Row>
                                                            : null
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <Row>
                                    <Col md={12}>
                                        <button className="btn_save" onClick={addMoreClicked}>Add More</button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <button className="btn_save" onClick={saveButtonClicked}>Save</button>
                                        <button className="btn_cancel" onClick={redirectTo}>Cancel</button>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </Container>
                </div>
            </div>
        </Fragment>
    )
}

export default VideoBranchingForm;