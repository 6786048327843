import React, { Fragment, createElement, useEffect, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import validator from "validator";
import fileUtils from "../../../utils/file-utils/fileUtils";
import videojs from 'video.js';
import Select from 'react-select';


//
import UploadFile from "../../common-file-upload/UploadFile";
import HttpService from "../../../Common/httpServices/http.service";
import Helper from "../../../Common/helper/helper";
import './imageForm.css';
import TextEditor from "../../../Common/editor/editor";
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import Loader from "../../../Common/loader/loder";
import { getFormQuizListAndOptions, getImageListAndOptions, getMainVideoListAndOptions } from "../common-methods/commonMethods";

const ImageForm = (props) => {
    // from states
    const [player, setPlayer] = useState();
    const [showTimeMm, setShowTimeMm] = useState("");
    const [showTimeSs, setShowTimeSs] = useState("");
    const [hideTimeMm, setHideTimeMm] = useState("");
    const [hideTimeSs, setHideTimeSs] = useState("");
    const [file, setFile] = useState("");
    const [label, setLabel] = useState("");
    const [labelVisible, setLabelVisible] = useState(false);
    const [selectActionLink, setSelectActionLink] = useState("");
    const [actionLink, setActionLink] = useState("");
    const [isLandingPage, setIslandingPage] = useState(false);
    const [isClickRequired, setIsClickRequired] = useState(false);
    const [isBranching, setIsBranching] = useState(false);
    const [status, setStatus] = useState(false);
    const [optionsInfo, setOptionsInfo] = useState({
        videoOptions: [],
        imageOptions: [],
        formOptions: [],
        quizOptions: []
    });


    //
    const [pauseTimeEnable, setPauseTimeEnable] = useState(false);
    const [pauseTimeSs, setPauseTimeSs] = useState(0);
    const [array, setArray] = useState([]);

    //
    const [name, setName] = useState("");
    const [size, setSize] = useState("");
    const [type, setType] = useState("");

    //
    const [newFile, setNewFile] = useState("");
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);

    //
    const [imageObj, setImageObj] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // instant changes
    if (props.isEdit !== false) {
        try {
            document.getElementById("image" + props.contentId).src = file;
            document.getElementById("label" + props.contentId).innerHTML = label;
        } catch (error) {
            // console.log(error); 
        }
    }

    async function cancelForm() {
        try {
            document.getElementById("image" + props.contentId).src = imageObj.imagePath;
            document.getElementById("label" + props.contentId).innerHTML = imageObj.label;
        } catch (e) { }

        // delete fresh image upload
        if (!imageObj.label) {
            
            try {
                let response = await HttpService.DELETE_BY_ID("/private/image", imageObj.id);
                console.log(response);
            } catch (err) {
                isSessionExpire();
            }
        }

        window.location.reload(true);
    }

    function saveForm() {
        let startTime = (Number(showTimeMm * 60) + Number(showTimeSs));
        let endTime = (Number(hideTimeMm * 60) + Number(hideTimeSs));

        const formInformation = {
            id: props.contentId,
            label: label,
            labelVisible: labelVisible ? 1 : 0,
            file: file,
            startTime: startTime,
            endTime: isClickRequired || pauseTimeEnable ? (startTime + 1) : endTime,
            name: name,
            size: size,
            type: type,
            actionLink: Number(selectActionLink) !== 2 && Number(selectActionLink) !== 5 ? JSON.stringify(actionLink) : actionLink,
            actionType: selectActionLink,
            isPauseEnable: pauseTimeEnable ? 1 : 0,
            pauseLength: (Number(pauseTimeSs) * 1000),
            isLandingPage: isLandingPage ? 1 : 0,
            isBranching: isBranching ? 1 : 0,
            isClickRequired: isClickRequired ? 1 : 0,
            status: startTime > 0 ? status ? 2 : 1 : 2,
            draggableProps: imageObj ? imageObj.draggableProps : ""
        }

        console.log("start time : " + startTime, "End time : " + endTime);
        // check validation
        if (Number(startTime) <= Number(endTime) || isClickRequired || pauseTimeEnable) {
            if (label && label.length < 30) {
                if (String(selectActionLink) === "" || selectActionLink === 0) {
                    props.setImgFormData(formInformation);
                } else if (String(selectActionLink) === "2") {
                    if (actionLink.startsWith("http") && validator.isURL(actionLink)) {
                        props.setImgFormData(formInformation);
                    } else {
                        alert("Please fill action valid link.");
                    }
                } else if (String(selectActionLink) === "5") {
                    if (actionLink) {
                        props.setImgFormData(formInformation);
                    } else {
                        alert("Please fill action content");
                    }
                } else if (String(selectActionLink) === "3") {
                    if (actionLink) {
                        props.setImgFormData(formInformation);
                    } else {
                        alert("Please select any video");
                    }
                } else if (String(selectActionLink) === "7") {
                    if (actionLink) {
                        props.setImgFormData(formInformation);
                    } else {
                        alert("Please select any form/survey");
                    }
                } else if (String(selectActionLink) === "8") {
                    if (actionLink) {
                        props.setImgFormData(formInformation);
                    } else {
                        alert("Please select any quiz/contest");
                    }
                }

            } else {
                alert("Please fill Image label and length of label should be less then 30 characters");
            }
        } else {
            alert("Enter valid start time and end time for image");
        }
    }

    async function fetchContentById(contentId) {
        // 
        console.log(contentId);
        // fetch the content media by id
        let response = null;
        try {
            response = await HttpService.GET_BY_ID("/private/image", contentId);
            console.log(response);
        } catch (err) {
            isSessionExpire();
        }

        // const baseUrl = response.data.object.baseUrl;
        const savedImageResponse = response.data.object[0]

        if (response.data.status === "SUCCESS") {
            //set object as globally
            setImageObj(savedImageResponse);

            // set fetch default value into states
            if (Number(savedImageResponse.startTime) > 59) {
                setShowTimeMm(Helper.beforeDecimal(Number(savedImageResponse.startTime / 60).toFixed(2)));
                setShowTimeSs(Helper.afterDecimal(Number(savedImageResponse.startTime / 60).toFixed(2)));
            } else {
                setShowTimeSs(savedImageResponse.startTime);
            }

            if (Number(savedImageResponse.endTime) > 59) {
                setHideTimeMm(Helper.beforeDecimal(String(Number(savedImageResponse.endTime / 60).toFixed(2))));
                setHideTimeSs(Helper.afterDecimal(String(Number(savedImageResponse.endTime / 60).toFixed(2))));
            } else {
                setHideTimeSs(savedImageResponse.endTime);
            }

            // if (savedImageResponse.imagePath.startsWith("http")) {
            setFile(savedImageResponse.imagePath);
            // } else {
            // setFile(baseUrl + savedImageResponse.imagePath);
            // }
            setLabelVisible(savedImageResponse.isLabelVisible === 1 ? true : false);
            setLabel(savedImageResponse.label);
            setName(savedImageResponse.imageName);
            setSize(savedImageResponse.imageSize);
            setType(savedImageResponse.imageType);
            setSelectActionLink(savedImageResponse.actionType);

            // setActionLink(savedImageResponse.actionContent);
            if (Number(savedImageResponse.actionType) !== 2 && Number(savedImageResponse.actionType) !== 5) {
                setActionLink(JSON.parse(savedImageResponse.actionContent));
            } else {
                setActionLink(savedImageResponse.actionContent);
            }

            setPauseTimeEnable(savedImageResponse.isPauseEnable === 1 ? true : false);
            setPauseTimeSs((savedImageResponse.pauseLength / 1000));
            setIslandingPage(savedImageResponse.isLandingPage === 1 ? true : false);
            setIsBranching(savedImageResponse.isBranching === 1 ? true : false);
            setIsClickRequired(savedImageResponse.isClickRequired === 1 ? true : false);
            setStatus(savedImageResponse.status === 1 ? false : true);
        }
    }



    async function getFormData(data) {
        if (data.type === 'URL') {
            setFile(data.formData);
            setName(Helper.getFileNameFromURL(data.formData));
            setType("video/png");
        } else {
            if (typeof data === 'string') {
                setFile(data);
                setName(Helper.getFileNameFromURL(data));
                setSize("");
                setType("image/" + Helper.getExtensionFromURL(data));
            } else {
                setFile(data.base64EncodedImg)
                setName(data.name);
                setSize(data.size);
                setType(data.type);
            }
        }
        handleClose();
    }

    function handelActionLink(e) {
        // console.log(e.target.value);
        // console.log(actionLink);
        setActionLink("");
        setSelectActionLink(e.target.value);
    }

    function pushEditorValue(value) {
        // console.log(value);
        setActionLink(value);
    }

    function timeToSec(min, sec) {
        return Number((min * 60)) + Number(sec);
    }

    const onChangeSelect = (instance) => {
        setActionLink(instance);
    }

    async function getInteractionsOptionsAndList() {
        const videoData = await getMainVideoListAndOptions(props.videoId);
        const imageData = await getImageListAndOptions(props.videoId);
        const formQuizData = await getFormQuizListAndOptions(props.videoId);

        setOptionsInfo({
            ...optionsInfo,
            ["videoOptions"]: videoData ? videoData.option : [],
            ["imageOptions"]: imageData ? imageData.option : [],
            ["formOptions"]: formQuizData ? formQuizData.filterData.options.formOptions : [],
            ["quizOptions"]: formQuizData ? formQuizData.filterData.options.quizOptions : []
        });
    }

    function showLabel(e) {
        let isChecked = e.target.checked;
        setLabelVisible(!labelVisible);

        let imageLabel = document.getElementById(`label${props.contentId}`);
        if (imageLabel) {
            if (isChecked) {
                imageLabel.style.display = 'block';
            } else if (!isChecked) {
                imageLabel.style.display = 'none';
            }
        } else {
            let uniqueId = `label_edit_${props.contentId}`;
            let isExists = document.getElementById(uniqueId);
            if (!isExists) {
                var myDiv = document.createElement("div");
                myDiv.id = uniqueId
                myDiv.style.textAlign = "center"
                if (isBranching) {
                    myDiv.style.color = "black";
                    myDiv.style.background = "#ffffffbf";
                } else {
                    myDiv.style.color = "#fff";
                    myDiv.style.background = "black";
                }
                myDiv.style.fontSize = "16px";
                myDiv.style.opacity = ".7";
                myDiv.innerHTML = `${label} `;
                document.getElementsByClassName(`image_div`)[0].append(myDiv);
            } else {
                document.getElementById(uniqueId).remove();
            }
        }
    }

    const onChangeIsBranching = (e) => {
        let isChecked = e.target.checked;
        setIsBranching(!isBranching);

        let imageElement = document.getElementById(`image${props.contentId}`);
        let imageLabel = document.getElementById(`label${props.contentId}`);

        if (imageElement) {
            if (isChecked) {
                imageElement.classList.add("img_branching");
            } else if (!isChecked) {
                imageElement.classList.remove("img_branching");
            }
        }
        

        if (imageLabel) {
            if (isChecked) {
                imageLabel.style.color = "black";
                imageLabel.style.background = "#ffffffbf";
            } else if (!isChecked) {
                imageLabel.style.color = "#fff";
                imageLabel.style.background = "black";
            }
        } else {
            let uniqueId = `label_edit_${props.contentId}`;
            let isExists = document.getElementById(uniqueId);
            if (isExists && isChecked) {
                isExists.style.color = "black";
                isExists.style.background = "#ffffffbf";
            } else {
                isExists.style.color = "#fff";
                isExists.style.background = "black";
            }
        }
    }

    useEffect(() => {
        var player = videojs('my-video');
        setPlayer(player);

        console.log(props.isEdit);
        fetchContentById(props.contentId);
        setArray(fileUtils.getArray(10));
        getInteractionsOptionsAndList();
    }, []);

    useEffect(() => {
        let totalSec = timeToSec(showTimeMm, showTimeSs);

        if (player) {
            player.currentTime(totalSec);
            player.controls(true);
        }

    }, [showTimeMm, showTimeSs]);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <div id="edit_modal">
                <div className="edit_modal_inner">
                    <div className="edit_modal_title">
                        <h2>Edit Image </h2>
                    </div>
                    <div className="edit_modal_fl">
                        <div className="edit_time">
                            <div className="editshowtime">
                                <label>Show Time</label>
                                <div className="showtime_inp">
                                    <input type="number" onChange={(e) => setShowTimeMm(e.target.value)} value={showTimeMm} className="show_time" name="showtime_mm" min="0" max="59" placeholder="MM" />
                                    <input type="number" onChange={(e) => { setShowTimeSs(e.target.value) }} value={showTimeSs} className="show_time" name="showtime_ss" min="0" max="59" placeholder="SS" />
                                </div>
                            </div>
                            <div className="edithidetime" style={{ display: !pauseTimeEnable && !isClickRequired ? "block" : "none" }}>
                                <label>Hide Time</label>
                                <div className="showtime_inp">
                                    <input type="number" onChange={(e) => setHideTimeMm(e.target.value)} value={hideTimeMm} className="hide_time" name="hidetime" min="0" max="59" placeholder="MM" />
                                    <input type="number" onChange={(e) => setHideTimeSs(e.target.value)} value={hideTimeSs} className="hide_time" name="hidetime" min="0" max="59" placeholder="SS" />
                                </div>
                            </div>
                        </div>
                        <div className="pauseFlex">
                            {
                                !isClickRequired ?
                                    <div className="edit_checkbox hotspot_edit_check">
                                        <div className="edit_hotspot_ck" style={{ paddingLeft: "12px" }}>
                                            <input type="checkbox" onChange={() => setPauseTimeEnable(!pauseTimeEnable)} value={pauseTimeEnable} checked={pauseTimeEnable} id="images_label" name="imglabel" />
                                            <label htmlFor="images"> Pause for Image</label>
                                        </div>
                                        <div style={{ display: pauseTimeEnable === false ? "none" : "block" }}>
                                            <div className="edit_selectbox">
                                                <select onChange={(e) => setPauseTimeSs(e.target.value)} value={pauseTimeSs} className="config_poster_inp">
                                                    {
                                                        array.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item}>{item} second</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                !pauseTimeEnable ?
                                    <div className="edit_checkbox">
                                        <input type="checkbox" onChange={() => setIsClickRequired(!isClickRequired)} checked={isClickRequired} id="images_is_click_required" name="click_required" className="config_poster_inp" />
                                        <label> Click Required</label>
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="pauseFlex">
                            <div className="edit_checkbox">
                                <input type="checkbox" onChange={() => setStatus(!status)} checked={status} id="images_is_click_required" name="make_as_draft" />
                                <label>Make as Draft</label>
                            </div>
                            <div className="edit_checkbox">
                                <input type="checkbox" onChange={showLabel} checked={labelVisible} id="images_label" name="imglabel" />
                                <label htmlFor="images">Show Label </label>
                            </div>
                        </div>

                        <div className="pauseFlex">
                            <div className="edit_checkbox">
                                <input type="checkbox" onChange={onChangeIsBranching} checked={isBranching} id="images_is_click_required" name="make_as_draft" />
                                <label>isBranching</label>
                            </div>
                        </div>

                        <label htmlFor="imageLabel" className="mt-3 mb-2" style={{ fontSize: "20px" }}>Image Label</label>
                        <div className="img_input_txt">
                            <input type="text" onChange={(e) => setLabel(e.target.value)} value={label} name="img_label" placeholder="Enter Image Label" className="config_poster_inp" style={{ marginTop: "0px" }} />
                        </div>

                        <label htmlFor="action" className="" style={{ fontSize: "20px", marginTop: "30px" }}>Action</label>
                        <div>
                            <div className="edit_selectbox_div">
                                <div className="edit_selectbox" style={{ marginTop: "0px", marginBottom: "5px" }}>
                                    <select onChange={handelActionLink} value={selectActionLink} className="config_poster_inp">
                                        <option value="">-- Select Action --</option>
                                        <option value="2">Open Link</option>
                                        <option value="5">Show Message</option>
                                        <option value="3">Switch Video</option>
                                        <option value="7">Switch Form/Survey</option>
                                        <option value="8">Switch Quiz/Contest</option>
                                    </select>
                                </div>
                            </div>
                            {
                                String(selectActionLink) === "2" ?
                                    <>
                                        <label htmlFor="actionURL" className="my-1" style={{ fontSize: "20px" }}>Action URL </label>
                                        <span style={{ display: "block" }}>Note : please enter valid url (https://www.domain.com or https://domain.com)</span>
                                        <div className="img_input_txt" style={{ paddingBottom: "25px" }}>
                                            <input onChange={(e) => setActionLink(e.target.value)} value={actionLink} type="url" name="open_link" placeholder="Enter URL (https://www.domain.com)" className="config_poster_inp" />
                                        </div>
                                    </>
                                    : null
                            }
                            {
                                String(selectActionLink) === "5" ?
                                    <div className="show_message" style={{ paddingBottom: "20px" }}>
                                        {/* <textarea onChange={(e) => setActionLink(e.target.value)} value={actionLink} rows={6} cols={29} name="show_message" placeholder="Enter HTML or Plain Text" ></textarea> */}
                                        <TextEditor width={450} height={250} content={actionLink} pushEditorValue={pushEditorValue} />
                                    </div>
                                    : null
                            }
                            {
                                String(selectActionLink) === "3" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Select Video"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionLink} // value  => { "value": value, "label": "label" }
                                            onChange={onChangeSelect}
                                            options={optionsInfo.videoOptions} />
                                    </div>
                                    : null
                            }
                            {
                                String(selectActionLink) === "7" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Form/Survey"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionLink}
                                            onChange={onChangeSelect}
                                            options={optionsInfo.formOptions} />
                                    </div>
                                    : null
                            }

                            {
                                String(selectActionLink) === "8" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Quiz/Contest"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionLink}
                                            onChange={onChangeSelect}
                                            options={optionsInfo.quizOptions} />
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="edit_image_box">
                            <label style={{ fontSize: "20px", marginBottom: "12px" }}>Image Content</label>
                            <div className="edit_image_div">
                                <img src={file} width={200} />
                            </div>
                            <button onClick={handleShow}>Change Image</button>
                        </div>

                        {/* <div className="edit_checkbox">
                            <input type="checkbox" onChange={() => setIslandingPage(!isLandingPage)} checked={isLandingPage} id="images_is_landing_page" name="landing_page" />
                            <label>Make as Landing Page</label>
                        </div> */}

                    </div>
                    <div className="edit_modal_save">
                        <button className="btn_save" onClick={saveForm}>Save</button>
                        <button className="btn_cancel" onClick={cancelForm}>Cancel</button>
                    </div>
                </div>
            </div>

            {/* Popup*/}
            <div>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title> <strong>Upload</strong> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UploadFile isMultiple="false" dataReturnType={"base64EncodedImg"} type="image" setFormData={getFormData} />
                    </Modal.Body>
                </Modal>
            </div>
        </Fragment>
    )
}

export default ImageForm;
