import httpService from "../../../Common/httpServices/http.service";
import { isSessionExpire } from "../../authentication/jwt/manageSession";


function makeOptions(data, value, name) {
    let options = [];
    data.map((obj, i) => {
        if (obj.status != 1)
            options.push({ value: obj[value], label: obj[name] });
    })
    return options;
}

async function getMainVideoListAndOptions(videoId) {
    let response = null;
    try {
        response = await httpService.GET(`/private/main-video/list?page-number=${0}&page-size=${10000}&video-name=${""}`);
        if (response.data.status === "SUCCESS") {
            let data = response.data.object.content;
            let videoOptions = makeOptions(data, "id", "label")

            // remove current video from list
            for (let i = 0; i < videoOptions.length; i++) {
                let o = videoOptions[i];
                if (o.value == videoId) {
                    videoOptions.splice(i, 1);
                }
            }

            return { list: response, option: videoOptions };
        } else {
            console.log("main video not found");
        }
    } catch (err) {
        isSessionExpire();
    }
}

async function getImageListAndOptions(videoId) {
    let response = null;
    try {
        response = await httpService.GET_BY_ID(`/private/image/lists`, videoId);
        if (response.data.status === "SUCCESS") {
            let data = response.data.object.imageList;
            const imageOptions = makeOptions(data, "id", "label")
            return { list: response, option: imageOptions };
        } else {
            console.log("images not found");
        }
    } catch (err) {
        isSessionExpire();
    }
}

async function getFormQuizListAndOptions(videoId) {
    let response = null;
    try {
        response = await httpService.GET_BY_ID(`/private/formSetting/list`, videoId);
        if (response.data.status === "SUCCESS") {
            let data = response.data.object.formSettingList;
            const filter = filterFormQuiz(data, "formType")
            return { data: response, filterData: filter };
        } else {
            console.log("form quiz not found");
        }
    } catch (err) {
        isSessionExpire();
    }
}

function filterFormQuiz(array, compareName) {
    const objectList = { formData: [], quizdata: [] }
    for (let i = 0; i < array.length; i++) {
        const obj = array[i];
        if (Number(obj[compareName]) === 1) {
            objectList.formData.push(obj);
        } else if (Number(obj[compareName]) === 2) {
            objectList.quizdata.push(obj);
        }
    }
    const optionsList = {
        formOptions: makeOptions(objectList.formData, "id", "formTitle"),
        quizOptions: makeOptions(objectList.quizdata, "id", "formTitle")
    }
    return { lists: objectList, options: optionsList };
}

export { getMainVideoListAndOptions, getImageListAndOptions, getFormQuizListAndOptions };