import axios from 'axios';
import ApiConfig from './../../api.config';

export default axios.create({
    baseURL: ApiConfig.getServer(),
    headers: {
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/json',
        // 'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Authorization': sessionStorage.getItem("token") ? 'Bearer ' + sessionStorage.getItem('token') : "",
        // "Content-Type": 'application/x-www-form-urlencoded'
        // 'Origin' : 'http://videoapi.truereach.co.in',
        // 'Access-Control-Allow-Origin':  'http://localhost:3000',
        // 'Access-Control-Allow-Methods': 'POST',
        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization'
    }
})

/*
 Error : org.springframework.web.multipart.MultipartException: Current request is not a multipart request
 solution : headers:{}   should be header:{}
 Reasion : here is accepted header not headers when we pass inside headers....
*/