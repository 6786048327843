import http from "./http.common";

class ServicesAPI {

  checkTokenExists() {
    let isTokenExists = sessionStorage.getItem("token") ? true : false;
    if (!isTokenExists) {
      window.location.reload(true);
    }
  }

  GET(ApiName) {
    this.checkTokenExists();
    return http.get(ApiName);
  }

  GET_BY_ID(ApiName, id) {
    this.checkTokenExists();
    return http.get(`${ApiName}/${id}`);
  }

  POST(ApiName, data) {
    this.checkTokenExists();
    return http.post(ApiName, data);
  }

  PUT(ApiName, id, data) {
    this.checkTokenExists();
    return http.put(`${ApiName}/${id}`, data);
  }

  PUT_WITHOUT_ID(ApiName, data) {
    this.checkTokenExists();
    return http.put(`${ApiName}`, data);
  }

  DELETE(ApiName) {
    this.checkTokenExists();
    return http.delete(`${ApiName}`);
  }

  DELETE_BY_ID(ApiName, id) {
    return http.delete(`${ApiName}/${id}`);
  }

  // public methods without token
  POST_NO_TOKEN(ApiName, data) {
    return http.post(ApiName, data);
  }

}

export default new ServicesAPI();