import React from 'react';

class FileUtils extends React.Component {

    getFileTypes(typeRequest) {
        let fileTypes = [];

        if (typeRequest == "image") {
            fileTypes = ["JPEG", "JPG", "PNG", "GIF"];
        } else if (typeRequest === "video") {
            fileTypes = ["MP4", "MKV", "MOV"];
        }

        return fileTypes;
    }

    getIcons() {
        const perfix = "/assets/icons/";
        const icons = [
            {
                iconName: `Cart`,
                iconUrl: `${perfix}cart_icon.png`
            },
            {
                iconName: `Plus`,
                iconUrl: `${perfix}plus_icon.png`
            },
            {
                iconName: `Question`,
                iconUrl: `${perfix}question_icon.png`
            },
            {
                iconName: `Start`,
                iconUrl: `${perfix}start_icon.png`
            },
        ]
        return icons;
    }

    getArray(length) {
        const array = [];
        for (let index = 0; index < length; index++) {
            array.push(index);
        }
        return array;
    }
}

export default new FileUtils();