import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import HttpService from '../../Common/httpServices/http.service';
//
import "./dashboard.css";
import UploadFile from '../common-file-upload/UploadFile';
import Helper from '../../Common/helper/helper';
import Loader from '../../Common/loader/loder';
import CommonPagination from '../../Common/pagination/pagination';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isSessionExpire } from '../authentication/jwt/manageSession';
import { Button, Modal } from 'react-bootstrap';
// import { Helmet } from 'react-helmet';

// import ScriptTag from 'react-script-tag/lib/ScriptTag';

const Dashboard = () => {
    // states
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [mainVideoList, setMainVideoList] = useState([]);
    const [pagination, setPagination] = useState({
        pageNo: '',
        pageSize: '',
        totalNoItem: '',
        totalPages: '',
        totalItems: ''
    });
    const [videoLabel, setVideoLabel] = useState("");

    // const
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getFormData = async (data) => {
        let requestData;
        let api = null;
        if (videoLabel) {
            setLoader(true);

            // set data
            if (data.type === 'URL') {
                requestData = {
                    "videoOriginalName": Helper.getFileNameFromURL(data.formData),
                    "videoContentType": 'video/' + Helper.getExtensionFromURL(data.formData),
                    "videoPath": data.formData,
                    "label": videoLabel,
                    "playerWidth": 640,
                    "playerHeight": 360
                }
                api = "/private/upload/main-video/url";
            } else if (data.type === 'UPLOAD') {
                data.formData.append(`label`, videoLabel); // label
                data.formData.append(`playerWidth`, 640);
                data.formData.append(`playerHeight`, 360);
                requestData = data.formData;
                api = "/private/upload/main-video/multipart";
            } else {
                console.log("unable to process request");
                return;
            }

            // send request to server for write uploaded files/videos. and redireact to player with video id.
            let response = null;
            try {

                response = await HttpService.POST(api, requestData);
                if (response.data.status === "SUCCESS") {
                    console.log(response);
                    alert("video saved successfully");
                }

            } catch (err) {
                isSessionExpire();
            }

            if (response.data.status === 'SUCCESS') {
                setLoader(false);
                data.type === 'URL'
                    ? redirectToPlayer(response.data.object.id)
                    : redirectToPlayer(response.data.object[0].id)

            } else {
                setLoader(false);
                console.log("formData is null or undefined");
            }
        } else {
            alert("Please Fill Video Label")
        }
    }

    const getMainVideoList = async (pageNo, pageSize, videoName) => {
        if (!videoName) {
            setLoader(true);
        }

        try {

            let response = null;
            try {
                response = await HttpService.GET(`/private/main-video/list?page-number=${pageNo}&page-size=${pageSize}&label=${videoName}`);
                // console.log(response);
            } catch (err) {
                setLoader(false);
                isSessionExpire();
            }
            const object = response.data;

            if (object.status === "SUCCESS") {
                setLoader(false);
                setMainVideoList(object.object.content)
                setPagination({
                    pageNo: object.object.number,
                    pageSize: object.object.size,
                    totalNoItem: object.object.numberOfElements,
                    totalPages: object.object.totalPages,
                    totalItems: object.object.totalElements
                });
            } else {
                setLoader(false);
                console.log("not able to fetch main video list !!!");
            }
        } catch (err) {
            setLoader(false);
        }
    }

    const redirectToPlayer = (id) => {
        // navigate("/player", {
        //     state: {
        //         id: id
        //     }
        // })
        window.location.href = `/player/${id}`;
    }
    
    const redirectToInstreamPlayer = (id) => {        
        window.location.href = `/instream-player/${id}`;
    }

    const deleteMainVideo = async (object) => {
        let response = null;
        let status = window.confirm("Do you want to delete this video ?");

        if (status === true) {
            // console.log();
            try {
                response = await HttpService.DELETE_BY_ID(`/private/main-video/delete`, object.id);
                // console.log(response);
                if (response.data.status === "SUCCESS") {
                    getMainVideoList(pagination.pageNo, pagination.pageSize, "");
                }
            } catch (err) {
                isSessionExpire();
            }
        }
    }

    const onChangeSearch = (e) => {
        let value = e.target.value;
        getMainVideoList(0, 12, value);
    }

    useEffect(() => {
        getMainVideoList(0, 12, "");
    }, []);

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            <div className='row' style={{ alignItems: "center", marginTop: "20px" }}>
                <div className='new_video col-md-7'>
                    <button type="button" className="btn btn-outline-success w-25 p-2" onClick={handleShow}>
                        <strong>Create New Video</strong>
                    </button>
                </div>
                <div className='col-md-5'>
                    <input onChange={onChangeSearch} type="text" placeholder="Search here" className="search_bar config_poster_inp" style={{ width: "60%", marginBottom: "0" }} />
                </div>
            </div>

            <div className="p-5">
                <Row>
                    {mainVideoList.length > 0 ?
                        mainVideoList.map((mainVideoObject, index) => {
                            return (
                                <Col md={3} key={index}>
                                    <div className="video_grid">
                                        <span onClick={() => redirectToPlayer(mainVideoObject.id)}>
                                            {mainVideoObject.posterImagePath ?
                                                mainVideoObject.posterImagePath.startsWith("http") ?
                                                    <img width="100" src={mainVideoObject.posterImagePath} />
                                                    :
                                                    <img width="100" src={"https://storage.googleapis.com/interactive-truevdo/add_poster_image_dir/" + mainVideoObject.posterImagePath} />
                                                : <img width="100" src={"https://storage.googleapis.com/interactive-truevdo/add_poster_image_dir/AOvTVQf3_20-01-2023_1674192406481.jpeg"} />
                                            }
                                        </span>
                                        <div className="flex_img">
                                            <h2><span title={mainVideoObject.label}>{mainVideoObject.label}</span></h2>
                                            <Row>
                                                <div className='dash_btns_add'>
                                                    <button className="btn btn-success" onClick={() => redirectToPlayer(mainVideoObject.id)}>Outstream</button>
                                                    <button className="btn btn-success" onClick={() => redirectToInstreamPlayer(mainVideoObject.id)}>Instream</button>
                                                    <button className='btn btn-danger' onClick={() => deleteMainVideo(mainVideoObject)}>Delete</button>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                        : null
                        // : <center><p>No Data Found !!!</p></center>
                    }
                </Row>
                {/* pagination start */}
                {
                    mainVideoList.length > 0 ?
                        pagination.totalPages > 1 ?
                            <Row>
                                <Col>
                                    <CommonPagination paginationProps={pagination} setData={getMainVideoList} />
                                </Col>
                            </Row>
                            : null
                        : null
                }
            </div>
            {/* Popup and mod*/}
            <div>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className='modalUpload'>
                    <Modal.Header closeButton>
                        <Modal.Title> <strong>Upload Video</strong> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <label htmlFor="videoLabel"><strong>Video Label *</strong></label><br />
                                <input type="text" onChange={(e) => setVideoLabel(e.target.value)} value={videoLabel} id="videoLabel" name="video_label" placeholder="Enter Video Label" className='config_poster_inp' style={{ width: '70%' }} />
                            </Col>
                        </Row>
                        <UploadFile isMultiple="false" dataReturnType={"formData"} type="video" setFormData={getFormData} />
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;