import React, { Fragment, useState, useEffect } from "react";
import "./../template.css"
import httpService from "../../../../Common/httpServices/http.service";
import { isSessionExpire } from "../../../authentication/jwt/manageSession";
import { Modal, Card, Col, Row, Container, Badge } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Loader from "../../../../Common/loader/loder";


const DefaultTemplates = () => {
    const [templateList, setTemplateList] = useState([]);
    const [videoTemplateList, setVideoTemplateList] = useState([]);
    const [loader, setLoader] = useState(false);

    const [templatePreview, setTemplatePreview] = useState("");
    const [show, setShow] = useState(false);
    const [cloneTemplateList, setCloneTemplateList] = useState([]);
    const [cbox, setCbox] = useState(false);
    const [showMsgNoData, setShowMsgNoData] = useState(false);

    const location = useLocation();
    const { videoId, templateId } = location.state;

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = async (object) => {
        setTemplatePreview(object.templateContent);
        const scriptTag = await makeJs(object);
        document.head.appendChild(scriptTag);

        setShow(true);
    }

    const makeJs = async (templateDataById) => {
        // Make Javascript for template
        const scriptTag = document.createElement('script');
        scriptTag.id = "template_js";
        scriptTag.type = "text/javascript";
        scriptTag.text = templateDataById.templateJs;
        scriptTag.crossOrigin = "anonymous";
        scriptTag.async = true;
        scriptTag.defer = true;
        return scriptTag;
    }

    const getTemplateList = async () => {
        setLoader(true)
        const resVidTemp = await getVideoTemplateListById();
        let response = null;
        if (resVidTemp.status === 204) {
            response = await httpService.GET("/private/template/list")
            if (response.data.status === "SUCCESS") {
                setLoader(false)
                let tempObject = response.data.object;
                if (tempObject.length <= 0) {
                    setShowMsgNoData(true);
                }
                setTemplateList(tempObject);
            }
            return true;
        }
        const vidTempObject = resVidTemp.data.object;
        try {
            response = await httpService.GET("/private/template/list")
            if (response.data.status == "SUCCESS") {
                setLoader(false)
                let tempObject = response.data.object;
                const filterTemplateList = tempObject.filter((elem) => !vidTempObject.find(({ templateName }) => elem.templateName === templateName));
                if (filterTemplateList.length <= 0) {
                    setShowMsgNoData(true);
                }
                setTemplateList(filterTemplateList);
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    const getVideoTemplateListById = async () => {
        let response = null;
        try {
            response = await httpService.GET_BY_ID("/private/video/template/list", videoId);
            if (response.data.status === "SUCCESS") {
                setVideoTemplateList(response.data.object);
            }
        } catch (err) {
            isSessionExpire();
        }
        return response;
    }

    const saveTemplateList = async (e) => {
        setLoader(true);
        let response = null;
        if (cloneTemplateList.length > 0)
            try {
                response = await httpService.POST("/private/video/template/save", cloneTemplateList)
                if (response.data.status === "SUCCESS") {
                    document.getElementById("temp_checkbox").checked = false;
                    redirectTo();
                }
            } catch (err) {
                isSessionExpire();
            }
        else
            alert("Please select template you want clone...");

    }

    const onChangeHandler = (e, object) => {

        const checkboxValue = e.target.checked;
        setCbox(e.target.checked);

        if (checkboxValue) {
            object["videoId"] = Number(videoId);
            delete object["id"];

            object['templateVideoMacros'] = object['templateMacros'];
            delete object['templateMacros'];

            if (object.templateVideoMacros.length > 0) {
                for (let i = 0; i < object.templateVideoMacros.length; i++) {
                    const element = object.templateVideoMacros[i];
                    delete element.id;
                    element.videoId = Number(videoId);
                }
            }

            setCloneTemplateList([...cloneTemplateList, object]);
        } else {
            for (let i = 0; i < cloneTemplateList.length; i++) {
                const element = cloneTemplateList[i];
                if (element.id === object.id) { cloneTemplateList.splice(i, 1); }
            }
        }
    }

    const redirectTo = () => {
        // window.location.href = `/player/${videoId}`;
        window.location.href = `/player/${videoId}&template=1`;
    }

    useEffect(() => {
        getTemplateList();
    }, []);

    console.log(cloneTemplateList);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <Container>
                <div className="vd_config">
                    {/* <Col md={12}> <h2>Template Listing</h2></Col>*/}
                    <Row style={{ flexFlow: "row", justifyContent: "space-between" }}>
                        <Col md={12} style={{ width: "auto" }}> <h2>Template Listing <Badge bg="primary">{videoId}</Badge></h2> </Col>
                        {/* <Col md={12} style={{ width: "auto" }}> <h2 >Video id : {videoId}</h2> </Col> */}
                    </Row>

                    <div className="row">
                        {
                            templateList && templateList.length > 0 ?
                                templateList.map((object, index) => {
                                    return (
                                        <Fragment key={index}>

                                            <Col md={3}>
                                                <div className="video_grid">
                                                    <span onClick={""}>
                                                        {
                                                            object.templatePath ?
                                                                <img width="100" src={object.templatePath} />
                                                                :
                                                                <img width="100" src={"https://storage.googleapis.com/interactive-truevdo/add_poster_image_dir/AOvTVQf3_20-01-2023_1674192406481.jpeg"} />
                                                        }
                                                    </span>
                                                    <div className="flex_img">
                                                        <h2><span title={object.templateName}>{object.templateName}</span></h2>
                                                        <Row>
                                                            <div className='dash_btns_add'>
                                                                <input value={cbox} id="temp_checkbox" type="checkbox" onChange={(e) => onChangeHandler(e, object)} />
                                                                <button className="btn btn-success" onClick={() => handleShow(object)}>Preview</button>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>

                                            {/* <div className="element_div col-md-3 m-3">
                                            <Card style={{ width: '18rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{object.templateName}</Card.Title>
                                                    <Card.Subtitle className="mt-1">
                                                        <Row>
                                                            <Col>
                                                                <input value={cbox} id="temp_checkbox" type="checkbox" onChange={(e) => onChangeHandler(e, object)} />
                                                            </Col>
                                                            <Col>
                                                                <button onClick={() => handleShow(object)}>Preview</button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Subtitle>
                                                </Card.Body>
                                            </Card>
                                        </div> */}
                                        </Fragment>
                                    )
                                })
                                : <div className="col-md-3 m-3"><span>{showMsgNoData ? "No data for create template" : null}</span></div>
                        }
                    </div>
                    <Row>
                        <Col md={12}>
                            <div className="col-md-12 mt-3">
                                {
                                    templateList.length > 0 ?
                                        templateList && templateList.length > 0 ?
                                            loader ?
                                                <button className="btn_save">
                                                    <div className="spinner-border text-primary" role="status">
                                                    </div>
                                                </button>
                                                :
                                                <button className="btn_save" onClick={(e) => saveTemplateList(e)}>
                                                    Save
                                                </button>
                                            : null
                                        : null
                                }
                                <button className="btn_cancel" onClick={redirectTo}>Back</button>
                            </div>
                        </Col>
                    </Row>

                </div>
            </Container>
            {/* Models */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Template Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: templatePreview }}></div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default DefaultTemplates;