import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import HttpService from '../../../Common/httpServices/http.service';
import { isSessionExpire } from '../../authentication/jwt/manageSession';
import VideoCloning from '../video-cloning/videoCloning';

const VideoCloneList = (props) => {
    const [cloneVideoInfo, setCloneVideoInfo] = useState([]);

    const fetchCloneVideoList = async () => {
        try {
            const response = await HttpService.GET_BY_ID("/private/clone/video", props.videoId);
            let data = response.data;
            let object = data.object;
            if (data.status == "SUCCESS") {
                // alert("Data Founded Successfully..");
                setCloneVideoInfo(object);
            } else if (data.status == "DATA_NOT_FOUND") {
                // alert("No Data Found!!!");
                setCloneVideoInfo([]);
            } else if (data.status == "FAILED") {
                // alert("There was an error!!!");
            }
        } catch (error) {

        }
    }

    const deleteVideo = async (id) => {
        let response = null;
        let status = window.confirm("Do you want to delete this video ?");

        if (status === true) {
            try {
                response = await HttpService.DELETE_BY_ID(`/private/main-video/delete`, id);
                // console.log(response);
                if (response.data.status === "SUCCESS") {
                    fetchCloneVideoList();
                }
            } catch (err) {
                isSessionExpire();
            }
        }
    }

    const redirectToPlayer = (id) => {
        window.location.href = `/player/${id}`;
    }

    useEffect(() => {
        fetchCloneVideoList();
    }, []);

    console.log(cloneVideoInfo);

    return (
        <Fragment>
            <Row>
                <Col className='d-flex justify-content-center m-3'>
                    <div className='cloneVideos'>
                        <VideoCloning videoName={props.videoName} videoId={props.videoId} />
                    </div>
                </Col>
            </Row>
            <Row className='m-2'>
                {
                    cloneVideoInfo.map((mainVideoObject, index) => {
                        return <Col sm={6} key={index}>
                            <div className="video_grid" style={{ position: "relative" }}>
                                <span className='bg-dark text-light' style={{ position: "absolute" }}>
                                    {`${mainVideoObject.playerWidth}*${mainVideoObject.playerHeight}`}
                                </span>
                                <span onClick={() => redirectToPlayer(mainVideoObject.id)}>
                                    {mainVideoObject.posterImagePath ?
                                        mainVideoObject.posterImagePath.startsWith("http") ?
                                            <img width="100" src={mainVideoObject.posterImagePath} />
                                            :
                                            <img width="100" src={"https://storage.googleapis.com/innovative-video/add_poster_image_dir/" + mainVideoObject.posterImagePath} />
                                        : <img width="100" src={"https://storage.googleapis.com/innovative-video/add_poster_image_dir/AOvTVQf3_20-01-2023_1674192406481.jpeg"} />
                                    }
                                </span>
                                <div className="flex_img">
                                    <h2><span title={mainVideoObject.label}>{mainVideoObject.label}</span></h2>
                                    <Row>
                                        <div className='dash_btns_add'>
                                            <button className="btn btn-success" onClick={() => redirectToPlayer(mainVideoObject.id)}>View</button>
                                            <button className='btn btn-danger' onClick={() => deleteVideo(mainVideoObject.id)}>Delete</button>
                                        </div>
                                    </Row>
                                </div>

                            </div>
                        </Col>
                    })
                }
                <div>
                    <strong className='d-flex justify-content-center m-3'>
                        {
                            cloneVideoInfo.length <= 0 ? "No Clone Video" : null
                        }
                    </strong>
                </div>
            </Row>
        </Fragment>
    );
}

export default VideoCloneList; 