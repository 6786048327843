import { Fragment, useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import { useNavigate, useLocation } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './adsForm.css';
import Select from 'react-select';
import HttpService from '../../../Common/httpServices/http.service';
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import Loader from "../../../Common/loader/loder";
import { Badge } from "react-bootstrap";

const AdsForm = (props) => {
    // PRE, MID, POST, CUSTOM, COMPANION
    const typeOptions = [
        { value: 'PRE', label: 'Pre-roll' },
        { value: 'MID', label: 'Mid-roll' },
        { value: 'POST', label: 'Post-roll' },
        { value: 'CUSTOM', label: 'Custom' },
        // { value: 'COMPANION', label: 'Companion' }
    ];
    const initialState = [
        {
            videoId: props.videoId,
            adType: "",
            adTagURL: "",
            startTime: "",
            adStartMin: "",
            adStartSec: ""
        }
    ];

    const [loader, setLoader] = useState(false);
    const [videoId, setVideoId] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const [formValues, setFormValues] = useState(initialState);
    const [videoConfig, setVideoConfig] = useState(false);

    const manageInputChange = (value, index, category) => {
        const updatedFormData = { ...formValues[index], [category]: value };
        const newState = [...formValues];
        newState[index] = updatedFormData;
        setFormValues(newState);
    }

    const manageSelectValues = (value, optionsArr) => {
        if (!value) {
            return '';
        }
        for (let i = 0; i < optionsArr.length; i++) {
            if (value === optionsArr[i].value) {
                return optionsArr[i];
            }
        }
    }

    const addMoreClicked = () => {
        const newState = [...formValues];
        newState.push({
            videoId: props.videoId,
            adType: "",
            adTime: "",
            adTagURL: ""
        });
        setFormValues(newState);
    }

    async function updateMainVideoConfig() {
        setLoader(true);        
        let response = null;
        try {
            let newVideoConfig = {...videoConfig};
            newVideoConfig.mainVideo.adsSetting = JSON.stringify(formValues);
            let formData = new FormData();
            formData.append(`mainVideo`, JSON.stringify(newVideoConfig.mainVideo));
            response = await HttpService.PUT("/private/update/main-video", videoId, formData);
            console.log(response);
        } catch (err) {
            isSessionExpire();
        }

        if (response.data.status == "SUCCESS") {
            alert("Saved Successfully")
            redirectTo("/instream-player");
        } else {
            console.log("Not Update!!!");
        }
        setLoader(false);
    }

    function cancelButtonClicked() {
        // navigate("/instream-player", { state: { id: props.videoId } })
    }

    const redirectTo = () => {
        window.location.href = `/instream-player/${props.videoId}`;
    }

    async function getAdsConfiguration(id) {
        try {
            const response = await HttpService.GET("/private/main-video/" + id);
            if (response.data.status === 'SUCCESS') {
                const videoConfigData = response.data.object;
                setVideoConfig(videoConfigData);
                if(videoConfigData.mainVideo.adsSetting) {
                    setFormValues(JSON.parse(videoConfigData.mainVideo.adsSetting));
                }
                // setFormValues(getByIdMainVideo.mainVideo);
                // setFiles(getByIdMainVideo.mainVideo.posterImagePath)
                // setVideoFile(getByIdMainVideo.mainVideo.videoPath);
            }
            return response;
        } catch (err) {
            isSessionExpire();
        }
    }

    const deleteAdRecord = async (e, formData, index) => {
        formValues.splice(index, 1);
        const remaningData = [...formValues];
        setFormValues(remaningData);
    }

    const changeStartTime = function (duration, adIndex, index) {
        const formValuesNew = { ...formValues[adIndex] };
        let adStartMin = formValuesNew.adStartMin || 0;
        let adStartSec = formValuesNew.adStartSec || 0;

        switch (index) {
            case 'adStartMin': {
                formValuesNew.startTime = adStartSec + Number(duration * 60);
                formValuesNew.adStartMin = Number(duration);
                break;
            }
            case 'adStartSec': {
                formValuesNew.startTime = Number(adStartMin * 60) + Number(duration);
                formValuesNew.adStartSec = Number(duration);
                break;
            }
            default: {
                break;
            }
        }
        const newState = [...formValues];
        newState[adIndex] = formValuesNew;
        setFormValues(newState);
    }

    useEffect(() => {
        let id = props.videoId;
        setVideoId(id);
        getAdsConfiguration(id);
    }, []);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <Container>
                <div className="vd_config">
                    <Col md={12}> <h2>Ads Setting<Badge bg="primary">{props.videoId}</Badge></h2></Col>
                    <div className="hotspot_position_1">
                        {
                            formValues.map((formData, index) => {
                                return (
                                    <div className="video_interaction" key={index}>
                                        <Row>
                                            <Col md={3}><h4>Ad Type</h4></Col>
                                            <Col md={9}>
                                                <Select
                                                    name="adType"
                                                    value={manageSelectValues(formData.adType, typeOptions)}
                                                    onChange={(e) => {
                                                        manageInputChange(e.value, index, 'adType');
                                                    }}
                                                    options={typeOptions}
                                                    classNamePrefix="select"
                                                />
                                            </Col>
                                        </Row>
                                        {
                                            formData.adType === 'CUSTOM' ?
                                            <Row>
                                                <Col md={3}><h4>Ad Time</h4></Col>
                                                <Col md={9}>
                                                    <div className="showtime_inp">
                                                        <input type="number" onChange={(e) => changeStartTime(e.target.value, index, 'adStartMin')} value={formData.adStartMin} className="show_time" name="showtime_mm" min="0" max="59" placeholder="MM" />
                                                        <input type="number" onChange={(e) => changeStartTime(e.target.value, index, 'adStartSec')} value={formData.adStartSec} className="show_time" name="showtime_ss" min="0" max="59" placeholder="SS" />
                                                    </div>
                                                </Col>
                                            </Row> 
                                            : null
                                        }
                                        <Row>
                                            <Col md={3}><h4>Ad Tag URL</h4></Col>
                                            <Col md={9}>
                                                <input
                                                    name="adTagURL"
                                                    value={formData.adTagURL}
                                                    onChange={(e) => {
                                                        manageInputChange(e.target.value, index, 'adTagURL');
                                                    }}
                                                />
                                            </Col>
                                        </Row>                                 
                                        {
                                            index !== 0 || formData.promptId ?
                                                <button className="btn btn-danger" style={{ width: "100px" }} onClick={(e) => deleteAdRecord(e, formData, index)}>delete</button>
                                                : null
                                        }
                                    </div>
                                )
                            })
                        }
                        <Row>
                            <Col md={12}>
                                <button className="btn_save" onClick={addMoreClicked}>Add More</button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <button className="btn_save" onClick={updateMainVideoConfig}>Save</button>
                                <button className="btn_cancel" onClick={redirectTo}>Cancel</button>
                            </Col>
                        </Row>
                    </div>

                </div>
            </Container>
        </Fragment>
    )
}

export default AdsForm;
