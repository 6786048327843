import React, { Fragment, useState, useRef, useEffect } from "react";
import './textForm.css';
import videojs from 'video.js';
import TextEditor from "../../../Common/editor/editor";
import fileUtils from "../../../utils/file-utils/fileUtils";
import httpService from "../../../Common/httpServices/http.service";
import { isSessionExpire } from "./../../authentication/jwt/manageSession"
import helper from "../../../Common/helper/helper";
import validator from "validator";
import Select from "react-select";
import { getFormQuizListAndOptions, getImageListAndOptions, getMainVideoListAndOptions } from "../common-methods/commonMethods";

const TextForm = (props) => {
    const [player, setPlayer] = useState();

    const [startMin, setStartMin] = useState('');
    const [startSec, setStartSec] = useState('');
    const [endMin, setEndMin] = useState('');
    const [endSec, setEndSec] = useState('');

    const [pauseForText, setPauseForText] = useState(false);
    const [pauseDuration, setPauseDuration] = useState("");
    const [action, setAction] = useState("");
    const [actionContent, setActionContent] = useState("");
    const [status, setStatus] = useState(false);

    // new added states
    const [textLabel, setTextLabel] = useState("");
    const [isLandingPage, setIslandingPage] = useState(false);
    const [isClickRequired, setIsClickRequired] = useState(false);
    const [useAsBtn, setUseAsBtn] = useState(false);
    const [editorContent, setEditorContent] = useState("");
    const [array, setArray] = useState([]);
    const [contentMediaId, setContentMediaId] = useState(undefined);
    const [textObj, setTextObj] = useState();

    const [helperVideos, setHelperVideos] = useState([]);
    const [helperImages, setHelperImages] = useState([]);
    const [optionsInfo, setOptionsInfo] = useState({
        videoOptions: [],
        imageOptions: [],
        formOptions: [],
        quizOptions: []
    });

    function cancelForm() {
        window.location.reload(true);
    }

    const saveForm = async () => {
        let textElement = null;

        if (contentMediaId) {
            textElement = document.getElementById("div_TXT_" + contentMediaId);
        }

        let startTime = (Number(startMin * 60) + Number(startSec));
        let endTime = (Number(endMin * 60) + Number(endSec));

        const saveText = {
            id: contentMediaId === 'BLANK' ? '' : contentMediaId,
            label: textLabel,
            textContent: editorContent,
            startTime: startTime,
            endTime: isClickRequired || pauseForText ? (startTime + 1) : endTime,
            actionContent: Number(action) !== 2 && Number(action) !== 5 ? JSON.stringify(actionContent) : actionContent,
            actionType: action === "" ? action : Number(action),
            isPauseText: pauseForText ? 1 : 0,
            textPauseLength: (Number(pauseDuration) * 1000),
            isLandingPage: isLandingPage ? 1 : 0,
            isClickRequired: isClickRequired ? 1 : 0,
            useAsBtn: useAsBtn ? 1 : 0,
            status: status ? 2 : 1,
            draggableProps: textObj ? textObj.draggableProps : "",
            width: contentMediaId ? textElement.offsetWidth : 0,
            height: contentMediaId ? textElement.offsetHeight : 0
        }

        if (Number(startTime) <= Number(endTime) || isClickRequired || pauseForText) {
            if (textLabel && textLabel.length < 30) {
                if (editorContent) {
                    // if (action) {

                    if (String(action) === "" || action === 0) {
                        props.setTextFormData(saveText);
                    } else if (String(action) === "2") {
                        if (actionContent.startsWith("http") && validator.isURL(actionContent)) {
                            props.setTextFormData(saveText);
                        } else {
                            alert("Please fill action valid link.");
                        }
                    } else if (String(action) === "5") {
                        if (actionContent) {
                            props.setTextFormData(saveText);
                        } else {
                            alert("Please fill action content");
                        }
                    } else if (String(action) === "3") {
                        if (actionContent) {
                            props.setTextFormData(saveText);
                        } else {
                            alert("Please select any video");
                        }
                    } else if (String(action) === "6") {
                        if (actionContent) {
                            props.setTextFormData(saveText);
                        } else {
                            alert("Please select any image");
                        }
                    } else if (String(action) === "7") {
                        if (actionContent) {
                            props.setTextFormData(saveText);
                        } else {
                            alert("Please select any form/survey");
                        }
                    } else if (String(action) === "8") {
                        if (actionContent) {
                            props.setTextFormData(saveText);
                        } else {
                            alert("Please select any quiz/contest");
                        }
                    }

                    // } else {
                    //     alert("Please select option in action.");
                    // }
                } else {
                    alert("Please fill content.");
                }
            } else {
                alert("Please fill label and label should be less then 30 characters.");
            }
        } else {
            alert("Enter valid start time and end time for text");
        }

    }

    const getTextById = async (contentId) => {
        let response = null;
        try {
            if (contentId !== 'BLANK') {
                response = await httpService.GET_BY_ID("/private/text-setting/view", contentId);
                if (response.data.status === "SUCCESS") {
                    const savedTextResponse = response.data.object.textSetting;
                    setTextObj(savedTextResponse);
                    if (Number(savedTextResponse.startTime) > 59) {
                        setStartMin(helper.beforeDecimal(Number(savedTextResponse.startTime / 60).toFixed(2)));
                        setStartSec(helper.afterDecimal(Number(savedTextResponse.startTime / 60).toFixed(2)));
                    } else {
                        setStartSec(savedTextResponse.startTime);
                    }

                    if (Number(savedTextResponse.endTime) > 59) {
                        setEndMin(helper.beforeDecimal(String(Number(savedTextResponse.endTime / 60).toFixed(2))));
                        setEndSec(helper.afterDecimal(String(Number(savedTextResponse.endTime / 60).toFixed(2))));
                    } else {
                        setEndSec(savedTextResponse.endTime);
                    }

                    setTextLabel(savedTextResponse.label);
                    setAction(savedTextResponse.actionType);

                    // setActionContent(savedTextResponse.actionContent);
                    if (Number(savedTextResponse.actionType) !== 2 && Number(savedTextResponse.actionType) !== 5) {
                        setActionContent(JSON.parse(savedTextResponse.actionContent));
                    } else {
                        setActionContent(savedTextResponse.actionContent);
                    }

                    setIslandingPage(savedTextResponse.isLandingPage === 1 ? true : false);
                    setIsClickRequired(savedTextResponse.isClickRequired === 1 ? true : false);
                    setEditorContent(savedTextResponse.textContent);
                    setPauseForText(savedTextResponse.isPauseEnable);
                    setPauseDuration(savedTextResponse.pauseLength / 1000);
                    setUseAsBtn(savedTextResponse.useAsBtn === 1 ? true : false);
                    setStatus(savedTextResponse.status === 1 ? false : true);
                }
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    function pushEditorValueData(value) {
        if (contentMediaId) {
            const appendDataOnScreen = document.getElementById("text" + contentMediaId);
            appendDataOnScreen.innerHTML = value;
        }
        setEditorContent(value);
    }

    function updateActionContent(value) {
        setActionContent(value);
    }

    function timeToSec(min, sec) {
        return Number((min * 60)) + Number(sec);
    }

    async function getInteractionsOptionsAndList() {
        const videoData = await getMainVideoListAndOptions(props.videoId);
        const imageData = await getImageListAndOptions(props.videoId);
        const formQuizData = await getFormQuizListAndOptions(props.videoId);

        setOptionsInfo({
            ...optionsInfo,
            ["videoOptions"]: videoData ? videoData.option : [],
            ["imageOptions"]: imageData ? imageData.option : [],
            ["formOptions"]: formQuizData ? formQuizData.filterData.options.formOptions : [],
            ["quizOptions"]: formQuizData ? formQuizData.filterData.options.quizOptions : []
        });
    }

    const onChangeSelect = (instance) => {
        setActionContent(instance);
    }

    useEffect(() => {
        var player = videojs('my-video');
        setPlayer(player);
        console.log(player);
        if (props.isEdit !== false) {
            getTextById(props.contentId);
            setContentMediaId(props.contentId);
        }
        setArray(fileUtils.getArray(10));
        getInteractionsOptionsAndList();
    }, []);

    useEffect(() => {
        let totalSec = timeToSec(startMin, startSec);
        if (player) {
            player.currentTime(totalSec);
        }

    }, [startMin, startSec]);

    // useEffect( () => {
    //     // setEditorContent('');
    //     // pushEditorValueData('');
    // }, [useAsBtn]);

    return (
        <>
            <div id="edit_modal">
                <div className="edit_modal_inner">
                    <div className="edit_modal_title">
                        <h2>{contentMediaId ? "Edit" : "Add"} Text</h2>
                    </div>
                    <div className="edit_modal_fl">
                        <div className="edit_time">
                            <div className="editshowtime">
                                <label>Show Time</label>
                                <div className="showtime_inp">
                                    <input type="number" value={startMin} onChange={(event) => setStartMin(event.target.value)} className="show_time" name="showtimemm" min="0" max="59" placeholder="MM" />
                                    <input type="number" value={startSec} onChange={(event) => setStartSec(event.target.value)} className="show_time" name="showtimess" min="0" max="59" placeholder="SS" />
                                </div>
                            </div>
                            {
                                !pauseForText ?
                                    <div className="edithidetime" style={{ display: !pauseForText && !isClickRequired ? "block" : "none" }}>
                                        <label>Hide Time</label>
                                        <div className="showtime_inp">
                                            <input type="number" value={endMin} onChange={(event) => setEndMin(event.target.value)} className="hide_time" name="hidetimemm" min="0" max="59" placeholder="MM" />
                                            <input type="number" value={endSec} onChange={(event) => setEndSec(event.target.value)} className="hide_time" name="hidetimess" min="0" max="59" placeholder="SS" />
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="pauseFlex">
                            {
                                !isClickRequired ?
                                    <div className="edit_checkbox" style={{ flexFlow: "column" }}>
                                        <label htmlFor="images"> <input type="checkbox" id="pauseText" name="pause_text" onChange={() => setPauseForText(!pauseForText)} checked={pauseForText} /> <span>Pause For Text</span></label>
                                        {
                                            pauseForText ?
                                                <div className="pause_selectbox">
                                                    <select value={pauseDuration} onChange={(e) => setPauseDuration(e.target.value)} className="config_poster_inp">
                                                        {
                                                            array.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item}>{item} second</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                : null
                                        }

                                    </div>
                                    : null
                            }

                            {
                                !pauseForText ?
                                    <div className="edit_checkbox" style={{ paddingLeft: "14px" }}>
                                        <input type="checkbox" onChange={() => setIsClickRequired(!isClickRequired)} checked={isClickRequired} id="text_is_click_required" name="click_required" />
                                        <label>Click Required</label>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="edit_checkbox" style={{ paddingLeft: "15px", margin: "0 auto" }}>
                            <input type="checkbox" onChange={() => setUseAsBtn(!useAsBtn)} checked={useAsBtn} id="useAsBtn" name="useAsBtn" />
                            <label style={{ fontSize: "16px", marginLeft: "10px" }}>Use As Button</label>
                            <div className="edit_checkbox">
                                <input type="checkbox" onChange={() => setStatus(!status)} checked={status} id="images_is_click_required" name="make_as_draft" />
                                <label>Make as Draft</label>
                            </div>
                        </div>

                        <label htmlFor="textLabel" className="mt-4 mb-2" style={{ fontSize: "20px" }}>Text Label</label>
                        <div className="">
                            <input type="text" onChange={(e) => setTextLabel(e.target.value)} value={textLabel} name="textLabel" placeholder="Enter Text Label" className='config_poster_inp' />
                        </div>

                        <label htmlFor="textContent" className="mt-4 mb-2" style={{ fontSize: "20px" }}>Content</label>
                        <div useAsBtn className="container" style={{ display: "flex", margin: "0 auto", justifyContent: "center" }}>
                            {useAsBtn ?
                                <TextEditor key={1} width={450} height={200} content={editorContent} pushEditorValue={pushEditorValueData} isCTA={true} />
                                :
                                <TextEditor key={2} width={450} height={400} content={editorContent} pushEditorValue={pushEditorValueData} />
                            }
                        </div>

                        <div>
                            <div className="action">
                                <label htmlFor="action" className="mt-4 mb-2" style={{ fontSize: "20px" }}>Action</label>
                                <span id="selAction" className="action_values" >
                                    <select value={action} onChange={(e) => {
                                        setActionContent("");
                                        setAction(e.target.value)
                                    }
                                    } className="config_poster_inp">
                                        <option value="">-- Select Action --</option>
                                        <option value="2">Open Link</option>
                                        <option value="5">Show Message</option>
                                        <option value="3">Switch Video</option>
                                        <option value="6">Switch Image</option>
                                        <option value="7">Switch Form/Survey</option>
                                        <option value="8">Switch Quiz/Contest</option>
                                    </select>
                                </span>
                            </div>

                            {
                                String(action) == '2' ?
                                    <>
                                        <label htmlFor="actionURL" className="my-1" style={{ fontSize: "20px" }}>Action URL </label>
                                        <span style={{ display: "block" }}>Note : please enter valid url (https://www.domain.com or https://domain.com)</span>
                                        <div className="action_link img_input_txt">
                                            <input value={actionContent} placeholder="Enter URL (https://www.domain.com)" onChange={(event) => setActionContent(event.target.value)} type="text" id="actionLink" name="action_link" className="config_poster_inp" />
                                        </div>
                                    </>
                                    : null
                            }

                            {
                                String(action) == '4' ?
                                    <div className="action_image">
                                        <label htmlFor="video">Select Image</label>
                                        <select value={actionContent.url} onChange={(event) => setActionContent(event.target.value)}>
                                            <option value="">--Select your option--</option>
                                            {
                                                helperImages.map((images) => {
                                                    return <option value={images.url}>{images.name}</option>;
                                                })
                                            }
                                        </select>
                                    </div>
                                    : null
                            }

                            {
                                String(action) == '5' ?
                                    <div className="action_message container" style={{ display: "flex", margin: "0 auto", justifyContent: "center" }}>
                                        <TextEditor width={450} height={280} content={actionContent} pushEditorValue={updateActionContent} style={{ margin: "0 auto" }} />
                                    </div>
                                    : null
                            }
                            {
                                String(action) === "3" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Select Video"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionContent} // value  => { "value": value, "label": "label" }
                                            onChange={onChangeSelect}
                                            options={optionsInfo.videoOptions} />
                                    </div>
                                    : null
                            }

                            {
                                String(action) === "6" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Select Image"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionContent}
                                            onChange={onChangeSelect}
                                            options={optionsInfo.imageOptions} />
                                    </div>
                                    : null
                            }

                            {
                                String(action) === "7" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Form/Survey"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionContent}
                                            onChange={onChangeSelect}
                                            options={optionsInfo.formOptions} />
                                    </div>
                                    : null
                            }

                            {
                                String(action) === "8" ?
                                    <div>
                                        <Select
                                            placeholder={"Search & Quiz/Contest"}
                                            isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                            value={actionContent}
                                            onChange={onChangeSelect}
                                            options={optionsInfo.quizOptions} />
                                    </div>
                                    : null
                            }
                        </div>

                        {/* <div className="edit_checkbox">
                            <input type="checkbox" onChange={() => setIslandingPage(!isLandingPage)} checked={isLandingPage} id="text_is_landing_page" name="landing_page" />
                            <label>Make as Landing Page</label>
                        </div> */}

                        <div className="buttons_save">
                            <button className="btn_save" onClick={saveForm} style={{ margin: "0 10px 0 0" }}>{contentMediaId && contentMediaId !== 'BLANK' ? "Update" : "Save"}</button>
                            <button className="btn_cancel" onClick={cancelForm}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default TextForm;