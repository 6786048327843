import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Error401 = () => {
    return (
        <Fragment>
            <center>
                <h1>401 Unauthorized Access</h1>
                <h3>Token Expire you need to re-login.</h3>
                <Button><Link className="text-light" to={'/'}>Login</Link></Button>
            </center>
        </Fragment>
    );
}

export default Error401;