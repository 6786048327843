import React, { Fragment, useState, useEffect } from "react";
import "./modifyTemplate.css"
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import httpService from "../../../Common/httpServices/http.service";
import { Row, Button, Col, Container, Table, Modal, Badge, Card, Tabs, Tab } from "react-bootstrap";
import UploadFile from '../../common-file-upload/UploadFile';
import { Link, useLocation } from "react-router-dom";
import { getVideoById } from "../../player/player-methods/playerMethods";
import PreviewPlayer from "../../player/preview-player/previewPlayer";
import Loader from "../../../Common/loader/loder";
import VariableHtml from "./show-macros/variableHtml";
import ShowTemplateVariables from "./show-macros/showTemplateVariables";
import { RotatingLines } from "react-loader-spinner";

const ModifyTemplate = (props) => {
    const [videoId, setVideoId] = useState("");
    const [templateId, setTemplateId] = useState("");
    const [templateInfo, setTemplateInfo] = useState();
    const [indexInfo, setIndexInfo] = useState({});
    const [mainVideoInfo, setMainVideoInfo] = useState();
    const [showPreviewTemp, setShowPreviewTemp] = useState("");
    const [show, setShow] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [loader, setLoader] = useState(false);
    const [groupId, setGroupId] = useState("");


    //
    const location = useLocation();

    // 
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);

    const handleCloseImage = () => {
        setShowImage(false);
    }

    const handleShowImage = (imageUrl) => {
        setShowImage(true);
        setPreviewImage(imageUrl);
    }

    const handleClose = () => {
        setShow(false);
        setIndexInfo("");
    }
    const handleShow = (event, index, macroName) => {
        setShow(true);
        setIndexInfo({
            event: event,
            index: index,
            macroName: macroName
        });
    }

    const getTemplateByTemplateId = async (templateId, videoId) => {
        let response = null;
        try {
            response = await httpService.GET(`/private/video/template/${templateId}/${1}`);
            if (response.data.status == "SUCCESS") {
                let object = response.data.object;
                setTemplateInfo(object);
            }

            // get mainVideo data
            if (videoId) {
                const data = await getVideoById(videoId);
                setMainVideoInfo(data);

                // append responsive css
                let responsePlayerCss = document.createElement('link');
                responsePlayerCss.href = `https://test.truereach.co.in/stg-video/responsive-css/responsive_${data.playerWidth}_${data.playerHeight}.css`;
                responsePlayerCss.rel = 'stylesheet';
                document.head.appendChild(responsePlayerCss);
            }

        } catch (err) {
            if (err.response.status == 403) {
                isSessionExpire();
            }
        }
    }

    const getFormData = async (data) => {
        
        handleClose();
        let templateInfoData = { ...templateInfo };

        if (indexInfo.macroName)
        for (let i = 0; i < templateInfoData.templateVideoMacros.length; i++) {
            const element = templateInfoData.templateVideoMacros[i];
            if (indexInfo.macroName === element.macro) {
                templateInfoData.templateVideoMacros[i]["newLabel"] = data.type == "URL" ? data.formData : data.base64EncodedImg;

                // templateInfoData.templateVideoMacros[i][name] = value;
            }
        }
        // templateInfoData.templateVideoMacros[indexInfo.index]["newLabel"] = data.type == "URL" ? data.formData : data.base64EncodedImg;
        setTemplateInfo(templateInfoData);

    }

    const updateTemplate = async () => {
        let response = null;
        setLoader(true);
        try {
            response = await httpService.PUT(`/private/video/template/update`, templateId, templateInfo);
            if (response.data.status == "SUCCESS") {
                getTemplateByTemplateId(templateId, videoId);
                alert("Macros Updated");
                setLoader(false);
            }
        } catch (err) {
            setLoader(false);
            if (err.response.status == 403) {
                isSessionExpire();
            }
        }
    }

    const redirectToSamePage = () => {
        window.location.href = `/player/${videoId}&template=1`;
    }

    const handleInputChange = (type, event, index, macroName) => {
        debugger
        const { name, value } = event.target;
        let templateInfoData = { ...templateInfo };

        if (macroName)
            for (let i = 0; i < templateInfoData.templateVideoMacros.length; i++) {
                const element = templateInfoData.templateVideoMacros[i];
                if (macroName === element.macro) {
                    templateInfoData.templateVideoMacros[i][name] = value;
                }
            }

        // templateInfoData.templateVideoMacros[index][name] = value;
        setTemplateInfo(templateInfoData);
    };

    const replaceMacros = async (template) => {
        // let modifiedTemplate = template.fixedContentVar;
        let modifiedTemplate = template.templateContentVar;

        for (let i = 0; i < template.templateVideoMacros.length; i++) {
            const element = template.templateVideoMacros[i];
            if (modifiedTemplate.includes(element.macro)) {
                // replace duplicate macros if used
                while (modifiedTemplate.includes(element.macro)) {
                    modifiedTemplate = modifiedTemplate.replace(element.macro, element.newLabel);
                }
            } else {
                // alert("Macro Not Found In Template: " + element.macro);
                console.log("Macro Not Found In Template: " + element.macro);
            }
        }
        return modifiedTemplate;
    }

    const previewTemplate = async () => {
        // upload js for preview template
        const isAppended = await makePreviewJs(templateInfo);
        if (isAppended) {
            const modifiedTemplate = await replaceMacros(templateInfo);
            setShowPreviewTemp(modifiedTemplate);
            handleShowPreview(); // show preview on player
        } else {
            alert("Js not append");
        }
    }

    const makePreviewJs = async (templateDataById) => {
        if (templateDataById) {
            const scriptTag = document.createElement('script');
            scriptTag.id = "template_js";
            scriptTag.type = "text/javascript";
            scriptTag.text = templateDataById.templateJs;
            scriptTag.crossOrigin = "anonymous";
            scriptTag.async = true;
            scriptTag.defer = true;
            // append js
            document.head.appendChild(scriptTag);
            return true;
        }
        return false;
    }

    function editMacroByGroupId(e) {
        let attributes = e.target.attributes;
        if (attributes.length > 0) {
            for (let i = 0; i < attributes.length; i++) {
                const attribute = attributes[i];
                if (attribute.name === "data-emacro") {
                    // console.log(attribute.value);
                    setGroupId(attribute.value);
                    setShowPreview(false);

                    setTimeout(() => {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }, 800);
                }
            }
        }
    }

    useEffect(() => {
        let state = location.state;
        setVideoId(state.videoId);
        setTemplateId(state.templateId);
        getTemplateByTemplateId(state.templateId, state.videoId);
    }, []);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <Container>
                <div className="edit_modal">
                    <div className="vd_config">
                        <Row style={{ flexFlow: "row", justifyContent: "space-between" }}>
                            <Col md={12} style={{ width: "auto" }}> <h2>Variables Details <Badge bg="primary">{videoId}</Badge></h2> </Col>
                            {/* <Col>
                                <input type="text" onChange={filterMacroData} value={groupId} placeholder="Search By GroupId / Macro" />
                            </Col> */}
                        </Row>
                    </div>

                    <section>
                        {
                            templateInfo ?

                                <ShowTemplateVariables setGroupId={setGroupId} groupId={groupId} templateInfo={templateInfo} handleShow={handleShow} handleShowImage={handleShowImage} handleInputChange={handleInputChange} />
                                :
                                <RotatingLines
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="96"
                                    visible={true}
                                />
                        }
                    </section>

                    {/* Upload Model Start  */}
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className='modalUpload'>
                        <Modal.Header closeButton>
                            <Modal.Title> <strong>Upload Image</strong> </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <UploadFile isMultiple="false" dataReturnType={"base64EncodedImg"} type="image" setFormData={getFormData} />
                        </Modal.Body>
                    </Modal>
                    {/* Upload Model End */}

                    {/* Preview Model */}
                    <Modal show={showPreview} onHide={handleClosePreview} className="videoPreview">
                        <Modal.Header closeButton>
                            <Modal.Title>Template Preview</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <PreviewPlayer
                                id={"preview-player-1"}
                                videoPath={mainVideoInfo && mainVideoInfo.videoPath}
                                renderContent={showPreviewTemp}
                                width={mainVideoInfo && mainVideoInfo.playerWidth}
                                // width={640}
                                height={mainVideoInfo && mainVideoInfo.playerHeight}
                                // height={360}
                                preload={"auto"}
                                autoPlay={false}
                                editMacro={editMacroByGroupId}
                            />

                            {/* <div dangerouslySetInnerHTML={{ __html: showPreviewTemp }}>
                            </div> */}

                        </Modal.Body>
                    </Modal>

                    {/* Image Preview Model */}
                    <Modal show={showImage} onHide={handleCloseImage} className="imagePreview">
                        <Modal.Header closeButton>
                            <Modal.Title>Image Preview</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={previewImage} alt={"image_" + previewImage} width="600" />
                        </Modal.Body>
                    </Modal>

                    <div className="col-md-12">
                        {templateInfo ?
                            templateInfo.templateVideoMacros.length > 0 ?
                                <>
                                    <button className="btn_cancel" onClick={() => previewTemplate()}>
                                        Preview
                                    </button>
                                    <button className="btn_cancel" onClick={updateTemplate}>
                                        Save
                                    </button>
                                </>
                                : ""
                            : ""
                        }
                        <button className="btn_cancel" onClick={redirectToSamePage}>
                            Back
                        </button>
                    </div>
                </div>
            </Container>
        </Fragment >
    )
}

export default ModifyTemplate;

