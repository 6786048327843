import React, { Fragment, useState, useEffect } from "react";
import './quiz_feedback.css';
import Helper from "../../../Common/helper/helper";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import httpService from "../../../Common/httpServices/http.service";
import TextEditor from "../../../Common/editor/editor";
import { getFormQuizListAndOptions, getImageListAndOptions, getMainVideoListAndOptions } from "../common-methods/commonMethods";
import Select from "react-select";

// main dropdown (quiz / form)
const Quiz_feedback = () => {
    const [status, setStatus] = useState(false);
    const [optionsInfo, setOptionsInfo] = useState({
        videoOptions: [],
        imageOptions: [],
        formOptions: [],
        quizOptions: []
    });

    // const [videoId, setVideoId] = useState(null);
    // const navigate = useNavigate();
    const location = useLocation();

    // console.log(location.state.id);
    let videoId;
    if (location.state.id) {
        videoId = location.state.id
    };

    // if (location.state.formId) {
    //     formId = location.state.formId
    // };

    const formDataSettings = {
        id: '',
        videoId: videoId,
        formTitle: '',
        startTime: 0,
        formStartMin: '',
        formStartSec: '',
        endTime: 0,
        formEndMin: '',
        formEndSec: '',
        isClickRequired: 1,
        actionType: '',
        actionContent: '',
        formMethod: '',
        formActionUrl: '',
        buttonLabel: 'Submit',
        buttonStyle: '',
        formType: '',
        draggableProps: '{\"height\":500,\"width\":420,\"cancel\":\"div\",\"grid\":[1,1],\"defaultPosition\":{\"x\":74,\"y\":28}}',
        fields: [
            {
                id: '',
                fieldName: '',
                type: '',
                label: '',
                options: [
                    {
                        value: "",
                        is_correct: ""
                    }
                ],
                isMandatory: "",
                validationMsg: "",
                otherValidation: "",
                allowSkipping: "",
                actionType: "",
                actionContent: "",
                timeLimit: ""
            }
        ],

    };
    // const [showTimeMm, setShowTimeMm] = useState("");
    // const [showTimeSs, setShowTimeSs] = useState("");
    // const [hideTimeMm, setHideTimeMm] = useState("");
    // const [hideTimeSs, setHideTimeSs] = useState("");

    const [formSetting, setFormSetting] = useState(formDataSettings);

    const formHandleChange = (value, category) => {
        let updatedFormData = { ...formSetting };
        updatedFormData[category] = value;
        console.log(updatedFormData);
        // 
        if (category === "actionType") {
            updatedFormData['actionContent'] = '';
        }
        setFormSetting(updatedFormData);
        // let newData = [...formSetting];
        // newData = updatedFormData;
        // console.log(formSetting.actionType);
    }
    // for fields
    const actionHandleChange = (value, index, category) => {
        let formSettingObj = { ...formSetting };
        formSettingObj.fields[index][category] = value;
        if (category === 'type') {

            formSettingObj.fields[index].options = [
                {
                    value: "",
                    is_correct: ""
                }
            ];
        }
        setFormSetting(formSettingObj);
    }

    const addMoreClicked = () => {
        let newData = { ...formSetting };
        newData.fields.push({
            id: '',
            type: '',
            label: '',
            options: [
                {
                    value: "",
                    is_correct: ""
                }
            ],
            isMandatory: "",
            validationMsg: "",
            otherValidation: "",
            allowSkipping: "",
            actionType: "",
            actionContent: "",
            timeLimit: ""
        }
        );
        setFormSetting(newData);
    }
    async function fieldDeleteBtn(index) {
        //         let formId = formSetting.id;
        let fieldId = formSetting.fields[index].id;
        console.log(fieldId);
        if (fieldId) {
            const response = await httpService.DELETE(`/private/formSetting/delete/${formId}/${fieldId}`);
            console.log(response)
            for (let i = 0; i < response.data.object.length; i++) {
                // update and covert options into json
                response.data.object[i].options = JSON.parse(response.data.object[i].options);
            }
            if (response.data.status === "SUCCESS") {
                const deleteField = { ...formSetting, fields: response.data.object };
                setFormSetting(deleteField);
            }
            console.log(response)
        }
        // const deleteBtnApi = await axios({
        //     method: 'delete',
        //     url: 'http://videoapi.truereach.co.in/private/formSetting/delete/' + formId + '/' + fieldId,

        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYWppZDk5OTAiLCJleHAiOjE2NzgxODI3NzQsImlhdCI6MTY3ODE3Mjc3NH0.HhfWr41neYwRZ5bxQrJk04CiQw_W0OJW8Vpv9767b3I"
        //     }

        // });
        // console.log(deleteBtnApi)
        else {
            const deleteBtns = { ...formSetting }
            console.log(formSetting);
            deleteBtns.fields.splice(index, 1);
            setFormSetting(deleteBtns);

        }
    }
    // const actionHandleChange = (value, index, category) => {
    const actionHandleOptionChange = (value, index, optionIndex, category) => {
        console.log(value, index, optionIndex, category)
        let formSettingObj = { ...formSetting };
        // formSettingObj.fields[index][category] = value;
        formSettingObj.fields[index].options[optionIndex][category] = value;
        if (formSettingObj.fields[index].type === 3 && category === 'is_correct') {
            formSettingObj.fields[index].options.map((newOptions, newIndex) => {
                if (newIndex !== optionIndex) {
                    formSettingObj.fields[index].options[newIndex][category] = '';
                }
            });
        }

        setFormSetting(formSettingObj);
        console.log(formSettingObj);
    }

    const addMoreOptionsClicked = (fieldIndex) => {
        let newData = { ...formSetting };
        console.log(newData);
        newData.fields[fieldIndex].options.push(

            {
                value: "",
                is_correct: ""
            }

        );
        setFormSetting(newData);
    }
    const optionDeleteBtn = (fieldIndex, optionIndex) => {
        const optionDelete = { ...formSetting }
        console.log(fieldIndex, optionIndex);

        // console.log(optionDelete);
        optionDelete.fields[fieldIndex].options.splice(optionIndex, 1);
        setFormSetting(optionDelete);
    }
    function formValidation(inputKey, type, fieldsIndex) {
        if (type === "F") {
            if (formSetting.fields[fieldsIndex][inputKey] === '') {
                return false;
            }
        }
        if (type === "C") {
            if (formSetting[inputKey] === '') {
                return false;
            }
        }
        return true;
    }
    async function saveForm() {
        // 

        // set video id
        // formSetting.videoId = videoId;
        // formSetting.startTime = (Number(showTimeMm * 60) + Number(showTimeSs));
        // formSetting.endTime = (Number(hideTimeMm * 60) + Number(hideTimeSs));

        let formSettingNew = { ...formSetting };
        // set pre data
        formSettingNew.status = formSettingNew.startTime > 0 ? status ? 2 : 1 : 2;
        formSettingNew.actionContent =
            Number(formSettingNew.actionType) !== 2 && Number(formSettingNew.actionType) !== 5
                ? JSON.stringify(formSettingNew.actionContent)
                : formSettingNew.actionContent;

        let actionContentErrorFlag = false;
        let errorFlag = false;
        if (formSettingNew.actionType != '' && formSettingNew.actionContent === '') {
            actionContentErrorFlag = true;
        }
        if (actionContentErrorFlag) {
            alert('Action content cannot be blank.');
            return false;
        }
        if (!formValidation('formType', 'C')) {
            alert('Fill all mandatory fields');
            return false;
        }


        for (let i = 0; i < formSettingNew.fields.length; i++) {
            // 1 form
            // 2 quiz 
            let errorCheckFlag = true;

            let formSettingNew = { ...formSetting };
            if (!formValidation('type', 'F', i)) {
                alert('Fill all mandatory fields');
                return false;
            }
            if (!formValidation('label', 'F', i)) {
                alert('Fill all mandatory fields');
                return false;
            }
            if (Number(formSettingNew.fields[i].type) === 2 || Number(formSettingNew.fields[i].type) === 3 || Number(formSettingNew.fields[i].type) === 4) {

                formSettingNew.fields[i].options.map(option => {
                    if (option.value === '') {
                        errorFlag = true;
                    }
                    if (option.is_correct === true) {
                        errorCheckFlag = false;
                    }
                });

                if (errorFlag) {
                    alert('You need to give atleast one option for each field and any option value cannot be blank.');
                    return false;
                }
                if (errorCheckFlag) {
                    alert('You need to check atleast one option');
                    return false;
                }
            }
        }

        for (let i = 0; i < formSettingNew.fields.length; i++) {
            formSettingNew.fields[i].options = typeof formSettingNew.fields[i].options === 'object' ? JSON.stringify(formSettingNew.fields[i].options) : formSettingNew.fields[i].options;

        }
        //         // console.log(formSettingNew);
        if (formSettingNew.id) {
                        const response = await httpService.PUT("/private/formSetting/update", formSettingNew.id, formSettingNew);

            if (response.data.status == "SUCCESS") {
                alert("Form Saved Successfully...");
            } else if (response.data.status == "REMOVE_SWITCHING") {
                alert("Form/Quiz used in switching with other interaction please remove first.");
                window.location.reload();
            }

            for (let i = 0; i < response.data.object.fields.length; i++) {
                // update and covert options into json 
                response.data.object.fields[i].options = JSON.parse(response.data.object.fields[i].options);
            }

            // 
            formSettingNew = { ...response.data.object };

            const startMinutes = Math.floor(formSettingNew.startTime / 60);
            const startSeconds = formSettingNew.startTime - startMinutes * 60;

            const endMinutes = Math.floor(formSettingNew.endTime / 60);
            const endSeconds = formSettingNew.endTime - endMinutes * 60;
            formSettingNew.formStartMin = startMinutes;
            formSettingNew.formStartSec = startSeconds;
            formSettingNew.formEndMin = endMinutes;
            formSettingNew.formEndSec = endSeconds;
            setFormSetting(formSettingNew);
            // response.data.object.formSetting.fields[i].options = JSON.parse(response.data.object.formSetting.fields[i].options);
            // setFormSetting(response.data.object);
            // const data = await axios({
            //     method: 'put',
            //     url: 'http://videoapi.truereach.co.in/private/formSetting/update/' + formSetting.fields.id,
            //     data: formSetting,
            //     headers: {
            //         'Access-Control-Allow-Origin': '*',
            //         "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYWppZDk5OTAiLCJleHAiOjE2NzgxODI3NzQsImlhdCI6MTY3ODE3Mjc3NH0.HhfWr41neYwRZ5bxQrJk04CiQw_W0OJW8Vpv9767b3I"
            //     }
            // });
            // console.log(data);
            redirectTo("/player");
        } else {
            //             const response = await httpService.POST("/private/formSetting", formSettingNew);
            // 
            for (let i = 0; i < response.data.object.fields.length; i++) {
                // update and covert options into json 
                response.data.object.fields[i].options = JSON.parse(response.data.object.fields[i].options);
            }
            formSettingNew = { ...response.data.object };

            const startMinutes = Math.floor(formSettingNew.startTime / 60);
            const startSeconds = formSettingNew.startTime - startMinutes * 60;

            const endMinutes = Math.floor(formSettingNew.endTime / 60);
            const endSeconds = formSettingNew.endTime - endMinutes * 60;
            formSettingNew.formStartMin = startMinutes;
            formSettingNew.formStartSec = startSeconds;
            formSettingNew.formEndMin = endMinutes;
            formSettingNew.formEndSec = endSeconds;
            setFormSetting(formSettingNew);

            getFormData(response.data.object.id)

            redirectTo("/player");

            // const data = await axios({
            //     method: 'post',
            //     url: 'http://videoapi.truereach.co.in/private/formSetting',
            //     data: formSetting,
            //     headers: {
            //         'Access-Control-Allow-Origin': '*',
            //         "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYWppZDk5OTAiLCJleHAiOjE2NzgxODI3NzQsImlhdCI6MTY3ODE3Mjc3NH0.HhfWr41neYwRZ5bxQrJk04CiQw_W0OJW8Vpv9767b3I"
            //     }
            // });
        }
        // console.log(data);
        // alert('Done');
        // console.log(formSetting);

    }
    // get api
    // let videoId;
    async function getFormData(formId) {
        // 
        console.log(1);
        console.log(location.state.formId);
        let response = null;

        try {
            response = await httpService.GET_BY_ID("/private/formSetting", formId);
            if (response.data.status === "SUCCESS") {
                // let parseData = JSON.parse(response.response.object.formSetting.fields.options);
                //  setFormSetting(parseData);
                for (let i = 0; i < response.data.object.formSetting.fields.length; i++) {
                    // update and covert options into json 
                    response.data.object.formSetting.fields[i].options = JSON.parse(response.data.object.formSetting.fields[i].options);
                }

                let formSettingNew = { ...response.data.object.formSetting };

                const startMinutes = Math.floor(formSettingNew.startTime / 60);
                const startSeconds = formSettingNew.startTime - startMinutes * 60;

                const endMinutes = Math.floor(formSettingNew.endTime / 60);
                const endSeconds = formSettingNew.endTime - endMinutes * 60;
                formSettingNew.formStartMin = startMinutes;
                formSettingNew.formStartSec = startSeconds;
                formSettingNew.formEndMin = endMinutes;
                formSettingNew.formEndSec = endSeconds;

                // deserialize 
                if (Number(formSettingNew.actionType) !== 2 && Number(formSettingNew.actionType) !== 5) {
                    formSettingNew.actionContent = JSON.parse(formSettingNew.actionContent);
                }
                setFormSetting(formSettingNew);
                setStatus(formSettingNew.status === 1 ? false : true)


                //  set fetch default value into states
                // if (Number(res.startTime) > 59) {
                //     setShowTimeMm(Helper.beforeDecimal(Number(res.startTime / 60).toFixed(2)));
                //     setShowTimeSs(Helper.afterDecimal(Number(res.startTime / 60).toFixed(2)) || 0);
                // } else {
                //     setShowTimeSs(res.startTime || 0);
                // }

                // if (Number(res.endTime) > 59) {
                //     setHideTimeMm(Helper.beforeDecimal(String(Number(res.endTime / 60).toFixed(2))));
                //     setHideTimeSs(Helper.afterDecimal(String(Number(res.endTime / 60).toFixed(2))) || 0);
                // } else {
                //     setHideTimeSs(res.endTime || 0);
                // }
            }
        } catch (error) { console.log(error); }

        // const response = await axios({
        //     method: 'get',
        //     url: 'http://videoapi.truereach.co.in/private/formSetting/75',

        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYWppZDk5OTAiLCJleHAiOjE2NzgxODI3NzQsImlhdCI6MTY3ODE3Mjc3NH0.HhfWr41neYwRZ5bxQrJk04CiQw_W0OJW8Vpv9767b3I"
        //     }

        // });


        // if (response.data.status === "DATA_NOT_FOUND") {

        // }

        // console.log(response);
        // 
    }

    const manageDuration = function (duration, index) {
        const formSettingNew = { ...formSetting };

        let formStartMin = formSettingNew.formStartMin;
        let formStartSec = formSettingNew.formStartSec;
        let formEndMin = formSettingNew.formEndMin;
        let formEndSec = formSettingNew.formEndSec;


        switch (index) {
            case 'formStartMin': {
                formSettingNew.startTime = formStartSec + Number(duration * 60);
                formSettingNew.formStartMin = Number(duration);
                break;
            }
            case 'formStartSec': {
                formSettingNew.startTime = Number(formStartMin * 60) + Number(duration);
                formSettingNew.formStartSec = Number(duration);
                break;
            }
            case 'formEndMin': {
                formSettingNew.endTime = formEndSec + Number(duration * 60);
                formSettingNew.formEndMin = Number(duration);
                break;
            }
            case 'formEndSec': {
                formSettingNew.endTime = Number(duration) + Number(formEndMin * 60);
                formSettingNew.formEndSec = Number(duration);
                break;
            }
            default: {
                break;
            }
        }
        // console.log(formSettingNew.startTime, formSettingNew.endTime, formSettingNew);
        setFormSetting(formSettingNew);
    }

    async function getInteractionsOptionsAndList() {
        const videoData = await getMainVideoListAndOptions(videoId);
        const imageData = await getImageListAndOptions(videoId);
        const formQuizData = await getFormQuizListAndOptions(videoId);
                setOptionsInfo({
            ...optionsInfo,
            ["videoOptions"]: videoData ? videoData.option : [],
            ["imageOptions"]: imageData ? imageData.option : [],
            ["formOptions"]: formQuizData ? formQuizData.filterData.options.formOptions : [],
            ["quizOptions"]: formQuizData ? formQuizData.filterData.options.quizOptions : []
        });
    }

    const onChangeSelect = (instance) => {
        // setActionLink(instance);
        // formSetting.actionContent
        setFormSetting({
            ...formSetting,
            ['actionContent']: instance
        });
    }

    useEffect(() => {
        let id = location.state.id;
        console.log(location.state)

        if (location.state.formId) {
            getFormData(location.state.formId);
        }
        getInteractionsOptionsAndList();
    }, []);

    console.log(videoId);
    // cancel button
    const redirectTo = (redireactUrlOrRoute) => {
        // navigate(redireactUrlOrRoute, { state: { id: video_id } })
        window.location.href = `${redireactUrlOrRoute}/${location.state.id}`;
    }
    // Delete Api

    // async function deleteFun() {
    //     const deleteBtnApi = await axios({
    //         method: 'delete',
    //         url: 'http://videoapi.truereach.co.in/private/formSetting/delete/{form_id}/{id}',

    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYWppZDk5OTAiLCJleHAiOjE2NzgwODcyNDMsImlhdCI6MTY3ODA3NzI0M30.CyBaIWlfD3PHFHBHaYZRmtH2HKI2jaS4PwZatJHrMgI"
    //         }

    //     });
    // }

    return (

        <Fragment>
            <Container>
                {/* video config start */}
                <div className="vd_config formQuizFields">
                    <Row style={{ flexFlow: "row", justifyContent: "space-between" }}>
                        <Col md={12} style={{ width: "auto" }}> <h2>Form Settings</h2> </Col>
                        <Col md={12} style={{ width: "auto" }}> <h2 >Video id : {videoId}</h2> </Col>
                    </Row>
                    {/* <Row>
                        <Col md={12} className="text-center my-3">
                            <h3>Form / Quiz Options</h3>
                        </Col>
                    </Row> */}
                    <fieldset>
                        <legend>Form / Quiz Options:</legend>
                        <Row>
                            <div className="pauseFlex">
                                <div className="edit_checkbox">
                                    <input type="checkbox" onChange={() => setStatus(!status)} checked={status} id="images_is_click_required" name="make_as_draft" />
                                    <label>Make as Draft</label>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col md={3}><h4>Form Type<span style={{ color: "red" }}>*</span></h4></Col>
                            <Col md={9} >
                                <select className="keepplaying_1 quizSelectStyle" value={formSetting.formType} onChange={(e) => { formHandleChange(e.target.value, 'formType') }}>
                                    <option value="">-- Please Select --</option>
                                    <option value="2">Quiz</option>
                                    <option value="1">Form</option>
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <h4>Form Label</h4>
                            </Col>
                            <Col md={9}>
                                <input type="text" id="poster_img" className="config_poster_inp quizSelectStyle" placeholder="Enter Form Label" value={formSetting.formTitle} onChange={(e) => { formHandleChange(e.target.value, 'formTitle') }} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "22px" }} className="">
                            <Col md={3}><h4>Start Time</h4></Col>
                            <Col md={9}>
                                <input type="number" className="show_time showtime_quiz quizSelectStyle" name="showtime" min="0" max="59" placeholder="MM" onChange={(e) => manageDuration(e.target.value, 'formStartMin')} value={formSetting.formStartMin} />
                                <input type="number" className="show_time showtime_quiz" name="showtime" min="0" max="59" placeholder="SS" onChange={(e) => manageDuration(e.target.value, 'formStartSec')} value={formSetting.formStartSec} />
                            </Col>
                        </Row>

                        {/* <Row>
                        <Col md={3}><h4>End Time</h4></Col>
                        <Col md={9} className="mt-3 mb-3">
                            <input type="number" className="show_time showtime_quiz" name="showtime" min="0" max="59" placeholder="MM" onChange={(e) => manageDuration(e.target.value, 'formEndMin')} value={formSetting.formEndMin} />
                            <input type="number" className="show_time showtime_quiz" name="showtime" min="0" max="59" placeholder="SS" onChange={(e) => manageDuration(e.target.value, 'formEndSec')} value={formSetting.formEndSec} />
                        </Col>
                    </Row> */}

                        {/* <Row>
                        <Col md={3}>
                            <h4>Click Required</h4>
                        </Col>
                        <Col md={9}>
                            <select className="keepplaying_1" value={formSetting.isClickRequired} onChange={(e) => { formHandleChange(e.target.value, 'isClickRequired') }}>
                                <option value="">Plase Select</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </Col>
                    </Row> */}

                        <Row>
                            <Col md={3}>
                                <h4>Action Type</h4>
                            </Col>
                            <Col md={9}>
                                <select className="keepplaying_1 quizSelectStyle" value={formSetting.actionType} onChange={(e) => { formHandleChange(e.target.value, 'actionType') }}>
                                    <option value="">-- Please Select --</option>
                                    {/* <option value="1">Track & Continue</option> */}
                                    <option value="2">Open link</option>
                                    <option value="5"> Show Message</option>
                                    <option value="3">Switch Video</option>
                                    <option value="6">Switch Image</option>
                                </select>

                            </Col>
                        </Row>
                        {
                            formSetting.actionType != '5' && formSetting.actionType != '' && formSetting.actionType == '2' ?
                                <Row>
                                    <Col md={3}>
                                        <h4>Action Content<span style={{ color: "red" }}>*</span></h4>
                                    </Col>
                                    <Col md={9}>
                                        <input type="text" id="poster_img" className="config_poster_inp quizSelectStyle" placeholder="Enter URL" value={formSetting.actionContent} onChange={(e) => { formHandleChange(e.target.value, 'actionContent') }} />
                                    </Col>
                                </Row>
                                : null
                        }
                        {
                            formSetting.actionType == '5' && formSetting.actionType != '' && formSetting.actionType != '2' ?
                                <Row>
                                    <Col md={3}>
                                        <h4>Text Editor<span style={{ color: "red" }}>*</span></h4>
                                    </Col>
                                    <Col md={9}>
                                        <div className="quizSelectStyle">
                                            <TextEditor width={450} height={350} content={formSetting.actionContent} pushEditorValue={(value) => { formHandleChange(value, 'actionContent') }} className="quizSelectStyle" />
                                        </div>
                                        {/* <input type="text" id="poster_img" className="config_poster_inp" placeholder="Enter" value={formSetting.actionContent} onChange={(e) => { formHandleChange(e.target.value, 'actionContent') }} /> */}
                                    </Col>
                                </Row>
                                : null
                        }
                        {
                            String(formSetting.actionType) === "3" ?
                                <Row>
                                    <Col md={3}></Col>
                                    <Col md={9}>
                                        <div className="quizSelectStyle">
                                            <Select
                                                placeholder={"Search & Select Video"}
                                                isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                                value={formSetting.actionContent}
                                                onChange={onChangeSelect}
                                                options={optionsInfo.videoOptions} />
                                        </div>
                                    </Col>
                                </Row>
                                : null
                        }
                        {
                            String(formSetting.actionType) === "6" ?
                                <Row>
                                    <Col md={3}></Col>
                                    <Col md={9}>
                                        <div className="quizSelectStyle">
                                            <Select
                                                placeholder={"Search & Select Image"}
                                                isSearchable={true} classNamePrefix="select" className="basic-multi-select"
                                                value={formSetting.actionContent}
                                                onChange={onChangeSelect}
                                                options={optionsInfo.imageOptions} />
                                        </div>
                                    </Col>
                                </Row>
                                : null
                        }
                        <Row style={{ marginTop: '20px' }}>
                            <Col md={3}>
                                <h4>Form Method</h4>
                            </Col>
                            <Col md={9}>
                                <select className="keepplaying_1 quizSelectStyle" value={formSetting.formMethod} onChange={(e) => { formHandleChange(e.target.value, 'formMethod') }}>
                                    <option value="">-- Please Select -- </option>
                                    <option value="0">Get</option>
                                    <option value="1">Post</option>
                                </select>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={3}>
                                <h4>Form Action URL</h4>
                            </Col>
                            <Col md={9}>
                                <input type="text" id="poster_img" className="config_poster_inp quizSelectStyle" placeholder="Enter Form Action URL" value={formSetting.formActionUrl} onChange={(e) => { formHandleChange(e.target.value, 'formActionUrl') }} />
                            </Col>
                        </Row>

                        {/* <Row>
                        <Col md={12} className="text-center my-3">
                            <h3>Button Options</h3>
                        </Col>
                    </Row> */}

                        <Row>
                            <Col md={3}>
                                <h4>Button Label (ex- submit, complete)</h4>
                            </Col>
                            <Col md={9}>
                                <input type="text" id="poster_img" className="config_poster_inp quizSelectStyle" placeholder="Button label" value={formSetting.buttonLabel} onChange={(e) => { formHandleChange(e.target.value, 'buttonLabel') }} />
                            </Col>
                        </Row>
                    </fieldset>

                    {/* <Row>
                        <Col md={3}>
                            <h4>Button Style</h4>
                        </Col>
                        <Col md={9}>
                            <input type="text" id="poster_img" className="config_poster_inp" placeholder="Enter font size, Color, Bg Color" value={formSetting.buttonStyle} onChange={(e) => { formHandleChange(e.target.value, 'buttonStyle') }} />
                        </Col>
                    </Row> */}




                    {/* textbox start */}

                    {/* <section className="hotspot_position_1"> */}
                    {

                        formSetting.fields.map((fieldData, index) => {
                            return (
                                <fieldset>
                                    <legend>Field Options:</legend>
                                    <div key={index}>
                                        <Row>
                                            <Col md={3}><h4>Select Input Type<span style={{ color: "red" }}>*</span></h4></Col>
                                            <Col md={9}>
                                                <select className="quizSelectStyle" value={fieldData.type} onChange={(e) => { actionHandleChange(e.target.value, index, 'type') }}>
                                                    <option value=''>-- Please Select --</option>
                                                    <option value={1} >Text Field</option>
                                                    <option value={2}>Select Box</option>
                                                    <option value={3}>Radio Button</option>
                                                    <option value={4}>Checkbox Button</option>
                                                    <option value={5}>Textarea</option>
                                                    <option value={6}>Datepicker</option>
                                                </select>
                                            </Col>
                                        </Row>

                                        {
                                            fieldData.type ?
                                                <>
                                                    <Row>
                                                        <Col md={3}>Field Name(key)</Col>
                                                        <Col md={9}>
                                                            <input type="text" id="poster_img" className="config_poster_inp quizSelectStyle" placeholder="Enter key name to send data on Form Action URL" value={fieldData.fieldName} onChange={(e) => { actionHandleChange(e.target.value, index, 'fieldName') }} />
                                                        </Col>
                                                    </Row>
                                                    {
                                                        fieldData.type != '' ?
                                                            <Row>
                                                                <Col md={3}><h4>Enter Question<span style={{ color: "red" }}>*</span></h4></Col>
                                                                <Col md={9}>
                                                                    <input type="text" id="poster_img" className="config_poster_inp quizSelectStyle" placeholder="Enter Question" value={fieldData.label} onChange={(e) => { actionHandleChange(e.target.value, index, 'label') }} />
                                                                </Col>
                                                            </Row> : null
                                                    }

                                                    <Row>
                                                        {
                                                            fieldData.type != '' && fieldData.type == 1 ?
                                                                <>
                                                                    <Col md={3}><h4>Enter Options<span style={{ color: "red" }}>*</span></h4></Col>
                                                                    <Col md={9}>
                                                                        <>
                                                                            {/* input type text start*/}
                                                                            {
                                                                                fieldData.options.map((optionData, optionIndex) => {
                                                                                    return (
                                                                                        <div key={optionIndex}>
                                                                                            {/* <input type="checkbox" name="forms_setting" value={optionData.is_correct} onChange={(e) => { actionHandleOptionChange(e.target.checked, index, optionIndex, 'is_correct') }}/> */}
                                                                                            <input type="text" id="poster_img" className="config_poster_inp quizSelectStyle" placeholder="Enter Option" value={optionData.value} onChange={(e) => { actionHandleOptionChange(e.target.value, index, optionIndex, 'value') }} />
                                                                                            {(formSetting.fields[index].options.length > 1) ?
                                                                                                <img src="/assets/delete.png" className="answer_imgs" onClick={(e) => { optionDeleteBtn(index, optionIndex) }} />
                                                                                                : ''
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })

                                                                            }

                                                                            <img src="/assets/plus.png" className="answer_imgs" onClick={(e) => { addMoreOptionsClicked(index) }} />

                                                                        </>
                                                                    </Col>
                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            fieldData.type == 6 ?
                                                                <Col md={3}><h4>Select Date</h4></Col>
                                                                : null
                                                        }
                                                        {
                                                            fieldData.type != 6 && fieldData.type != '' && fieldData.type != 1 ?
                                                                <Col md={3}>Mange Options & Answer<br />(click checkbox/radio for correct answer)</Col>
                                                                : null
                                                        }
                                                        <Col md={9}>
                                                            {/* {
                                                    fieldData.type != '' && fieldData.type != '4' && fieldData.type != '5' ?
                                                        <input type="text" id="poster_img" className="config_poster_inp" placeholder="Enter Option" />
                                                        : null
                                                } */}
                                                            {/* select type  start*/}
                                                            {
                                                                fieldData.type == 2 ?
                                                                    <>
                                                                        {
                                                                            fieldData.options.map((optionData, optionIndex) => {
                                                                                return (
                                                                                    <div key={optionIndex}>
                                                                                        <input type="checkbox" name="forms_setting" checked={optionData.is_correct} onChange={(e) => { actionHandleOptionChange(e.target.checked, index, optionIndex, 'is_correct') }} />
                                                                                        <input type="text" id="poster_img" className="config_poster_inp" placeholder="Enter Option" value={optionData.value} onChange={(e) => { actionHandleOptionChange(e.target.value, index, optionIndex, 'value') }} />
                                                                                        {(formSetting.fields[index].options.length > 1) ?
                                                                                            <img src="/assets/delete.png" className="answer_imgs" onClick={(e) => { optionDeleteBtn(index, optionIndex) }} />
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })

                                                                        }
                                                                        <img src="/assets/plus.png" className="answer_imgs" onClick={(e) => { addMoreOptionsClicked(index) }} />

                                                                    </>
                                                                    : null
                                                            }
                                                            {/* checkbox  start*/}
                                                            {
                                                                fieldData.type == 4 ?
                                                                    <>
                                                                        {
                                                                            fieldData.options.map((optionData, optionIndex) => {
                                                                                return (
                                                                                    <div key={optionIndex}>
                                                                                        <input type="checkbox" name="forms_setting" checked={optionData.is_correct} onChange={(e) => { actionHandleOptionChange(e.target.checked, index, optionIndex, 'is_correct') }} />
                                                                                        <input type="text" id="poster_img" className="config_poster_inp" placeholder="Enter Option" value={optionData.value} onChange={(e) => { actionHandleOptionChange(e.target.value, index, optionIndex, 'value') }} />
                                                                                        {(formSetting.fields[index].options.length > 1) ?
                                                                                            <img src="/assets/delete.png" className="answer_imgs" onClick={(e) => { optionDeleteBtn(index, optionIndex) }} />
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })

                                                                        }
                                                                        <img src="/assets/plus.png" className="answer_imgs" onClick={(e) => { addMoreOptionsClicked(index) }} />

                                                                    </>
                                                                    : null
                                                            }
                                                            {/* radio start */}
                                                            {
                                                                fieldData.type == 3 ?
                                                                    <>
                                                                        {
                                                                            fieldData.options.map((optionData, optionIndex) => {
                                                                                return (
                                                                                    <div key={optionIndex}>
                                                                                        <input type="checkbox" name="forms_setting" checked={optionData.is_correct} onClick={(e) => { actionHandleOptionChange(e.target.checked, index, optionIndex, 'is_correct') }} />
                                                                                        <input type="text" id="poster_img" className="config_poster_inp" placeholder="Enter Option" value={optionData.value} onChange={(e) => { actionHandleOptionChange(e.target.value, index, optionIndex, 'value') }} />
                                                                                        {(formSetting.fields[index].options.length > 1) ?
                                                                                            <img src="/assets/delete.png" className="answer_imgs" onClick={(e) => { optionDeleteBtn(index, optionIndex) }} />
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })

                                                                        }
                                                                        <img src="/assets/plus.png" className="answer_imgs" onClick={(e) => { addMoreOptionsClicked(index) }} />

                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                fieldData.type == 5 ?
                                                                    <>
                                                                        {
                                                                            fieldData.options.map((optionData, optionIndex) => {
                                                                                return (
                                                                                    <div key={optionIndex}>
                                                                                        <textarea id="poster_img" className="config_poster_inp quizSelectStyle" placeholder="Enter Option" rows="5" value={optionData.value} onChange={(e) => { actionHandleOptionChange(e.target.value, index, optionIndex, 'value') }}>
                                                                                        </textarea>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                fieldData.type == 6 ?
                                                                    <>
                                                                        {
                                                                            fieldData.options.map((optionData, optionIndex) => {
                                                                                return (
                                                                                    <div key={optionIndex}>
                                                                                        <input type="date" id="poster_img" className="config_poster_inp quizSelectStyle" value={optionData.value} onChange={(e) => { actionHandleOptionChange(e.target.value, index, optionIndex, 'value') }} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                    : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                                : null
                                        }

                                        <Row>
                                            <Col md={3}><h4>Is Mandatory</h4></Col>
                                            <Col md={9}>
                                                <select className="quizSelectStyle" value={fieldData.isMandatory} onChange={(e) => { actionHandleChange(e.target.value, index, 'isMandatory') }}>
                                                    <option value="">-- Please Select --</option>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>

                                            </Col>
                                        </Row>
                                        {
                                            fieldData.isMandatory == '1' ?
                                                <Row>
                                                    <Col md={3}><h4>Validating Message</h4></Col>
                                                    <Col md={9}>
                                                        <input type="text" id="poster_img" className="config_poster_inp quizSelectStyle" placeholder="Enter Message" value={fieldData.validationMsg} onChange={(e) => { actionHandleChange(e.target.value, index, 'validationMsg') }} />
                                                    </Col>
                                                </Row>
                                                : null
                                        }
                                        {/* {
                                        formSetting.formType == 2 ?

                                            <>
                                                <Row>
                                                    <Col md={3}><h4>Allow Skipping</h4></Col>
                                                    <Col md={9}>
                                                        <select className="" value={fieldData.allowSkipping} onChange={(e) => { actionHandleChange(e.target.value, index, 'allowSkipping') }}>
                                                            <option>-- Please Select --</option>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={3}><h4>Button Click Action</h4></Col>
                                                    <Col md={9}>
                                                        <select className="keepplaying_1" value={fieldData.actionType} onChange={(e) => { actionHandleChange(e.target.value, index, 'actionType') }}>
                                                            <option value="">-- Please Select --</option>
                                                            <option value="0">Track & Continue</option>
                                                            <option value="1">Open link</option>
                                                            <option value="2"> Show Message</option>
                                                        </select>
                                                    </Col>
                                                </Row>
                                                {
                                                    fieldData.actionType != '0' && fieldData.actionType != '' ?
                                                        <Row>
                                                            <Col md={3}>Action Content</Col>
                                                            <Col md={9}>
                                                                <input type="text" id="poster_img" className="config_poster_inp" placeholder="Enter" value={fieldData.actionContent} onChange={(e) => { actionHandleChange(e.target.value, index, 'actionContent') }} />
                                                            </Col>
                                                        </Row>
                                                        : null
                                                }

                                                <Row>
                                                    <Col md={3}><h4>Auto Hide</h4></Col>
                                                    <Col md={9}>
                                                        <input type="number" className="show_time showtime_quiz" name="showtime" min="0" max="59" placeholder="SS" value={fieldData.timeLimit} onChange={(e) => { actionHandleChange(e.target.value, index, 'timeLimit') }} />
                                                    </Col>
                                                </Row>
                                            </>
                                            : null
                                    } */}
                                        {(formSetting.fields.length > 1) ?
                                            <button className="add_more_setting btn_save" onClick={() => fieldDeleteBtn(index)}>Delete</button>
                                            : ''
                                        }

                                    </div>
                                </fieldset>

                            )
                        })

                    }
                    <Row>
                        <Col md={12} style={{ display: "flex", alignItems: "center" }}>
                            {
                                // formSetting.formType !== '' ?
                                <button className="add_more_setting btn_save" onClick={addMoreClicked}>Add More</button>
                                // : ''
                            }
                        </Col>
                    </Row>
                    {/* textbox end */}
                    <Row>
                        <Col md={12}>
                            <button className="btn_save" onClick={saveForm}>Save</button>
                            <button className="btn_cancel" onClick={() => redirectTo("/player")}>Cancel</button>

                        </Col>
                    </Row>

                    {/* </section> */}


                    {/* <h2>Quiz will start form !!</h2> */}


                </div>
            </Container>
        </Fragment>
    )
}
export default Quiz_feedback;
